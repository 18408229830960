import React, { Dispatch, FC, memo, useCallback } from 'react';
import EnrollmentCard from './EnrollmentCard';
import PossibleExistingPractices from './PossibleExistingPractices';
import EnrollmentPractice from 'types/enrollments/EnrollmentPractice';
import Row from 'components/Bootstrap/Row';
import Col from 'components/Bootstrap/Col';
import EnrollmentFormInput from './EnrollmentFormInput';
import EnrollmentResourceNames from 'types/enrollments/EnrollmentResourceNames';
import { DefaultEnrollmentPractice } from 'types/enrollments/Practice';
import FieldsReducerAction from 'types/enrollments/FieldsReducerAction';
import { GetNpiSummary, NpiSummaryCache } from 'hooks/enrollments/useNpiSummaries';
import EnrollmentError from 'types/enrollments/EnrollmentError';
import MemoizedResourceCard from './MemoizedResourceCard';

const RESOURCE_NAME = EnrollmentResourceNames.enrollment_practices;

// eslint-disable-next-line react/display-name
const Body = (
  { index, resource, resourceDispatch, getNpiSummary, errors }:
  {
    index: number;
    resource: EnrollmentPractice;
    resourceDispatch: Dispatch<FieldsReducerAction>;
    getNpiSummary: GetNpiSummary;
    errors: EnrollmentError[];
  }
) => {
  const inputProps = {
    index,
    resourceName: RESOURCE_NAME,
    resource,
    resourceDispatch,
    errors
  };

  return (
    <>
      <Row>
        <Col sm={3}>
          <EnrollmentFormInput
            label='NPI'
            attribute='npi'
            {...inputProps}
          />
        </Col>
        <Col sm={8}>
          <EnrollmentFormInput
            label='Practice Name'
            attribute='practice_name'
            {...inputProps}
          />
        </Col>
        <Col sm={1}>
          <EnrollmentFormInput
            label='Accept'
            attribute='accept'
            type='checkbox'
            {...inputProps}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <EnrollmentFormInput
            label='Street'
            attribute='street'
            {...inputProps}
          />
        </Col>
        <Col sm={3}>
          <EnrollmentFormInput
            label='Suite'
            attribute='suite'
            {...inputProps}
          />
        </Col>
        <Col sm={2}>
          <EnrollmentFormInput
            label='City'
            attribute='city'
            {...inputProps}
          />
        </Col>
        <Col sm={2}>
          <EnrollmentFormInput
            label='State'
            attribute='state'
            {...inputProps}
          />
        </Col>
        <Col sm={2}>
          <EnrollmentFormInput
            label='Zip'
            attribute='zip'
            {...inputProps}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <EnrollmentFormInput
            label='Phone Number'
            attribute='phone_number'
            {...inputProps}
          />
        </Col>
        <Col sm={3}>
          <EnrollmentFormInput
            label='Fax Number'
            attribute='fax_number'
            {...inputProps}
          />
        </Col>
        <Col sm={3}>
          <EnrollmentFormInput
              label='Tax Id'
              attribute='tax_id'
              {...inputProps}
          />
        </Col>
      </Row>
    </>
  );
};

const AdditionalSummaries:
  // eslint-disable-next-line react/display-name
  FC<{
    index: number;
    resource: EnrollmentPractice;
    resourceDispatch: Dispatch<FieldsReducerAction>
  // eslint-disable-next-line react/display-name
  }> = memo(
    ({
      index,
      resource,
      resourceDispatch
    }) => {

      const fillValuesFromPossibleExistingPractices = (
        practiceValues: EnrollmentPractice,
        index: number
      ) => {
        resourceDispatch({
          resourceName: RESOURCE_NAME,
          type: 'setWithPracticeValues',
          index,
          practiceValues
        });
      };

      const usePracticeValues = useCallback((possiblePractice) => {
        fillValuesFromPossibleExistingPractices(
          possiblePractice,
          index
        );
      }, []);

      return (
        <PossibleExistingPractices
          practice={resource}
          usePracticeValues={usePracticeValues}
        />
      );
    }
  );

const PracticesCard: FC<{
  practices: (EnrollmentPractice | DefaultEnrollmentPractice)[];
  npiSummaries: NpiSummaryCache;
  getNpiSummary: GetNpiSummary;
  resourceDispatch: Dispatch<FieldsReducerAction>;
  errors: EnrollmentError[];
}> = ({
  practices,
  npiSummaries,
  getNpiSummary,
  resourceDispatch,
  errors
}) => {
  return (
    <EnrollmentCard
      title='Practices'
      npiSummary
      AdditionalSummaries={AdditionalSummaries}
      resources={practices}
      resourceName={RESOURCE_NAME}
      resourceDispatch={resourceDispatch}
      npiSummaries={npiSummaries}
      getNpiSummary={getNpiSummary}
      errors={errors}
      allowRemove={false}
    >
      {Body}
    </EnrollmentCard>
  );
};

export default MemoizedResourceCard(PracticesCard);
