import { useState } from 'react';

type UseDebouncedFetchType = (timeout?: number) => (cb: () => void) => void;

const useDebouncedFetch: UseDebouncedFetchType = (timeout = 500) => {
  const [fetchTimeout, setFetchTimeout] = useState(null);

  const debouncedFetch = (cb) => {
    if (fetchTimeout) clearTimeout(fetchTimeout);

    setFetchTimeout(
      setTimeout(() => {
        cb();
      }, timeout)
    );
  };

  return debouncedFetch;
};

export default useDebouncedFetch;
