import flatpickr from 'flatpickr';

type FlatPicker = (id: string,handleOnChange) => void;

const flatPicker: FlatPicker = (id,handleOnChange) => {
  flatpickr(`#${id}`, {
    allowInput: true,
    onChange: function(selectedDate,dateStr,instance){
      handleOnChange(dateStr);
    }
  });
};

export default flatPicker;