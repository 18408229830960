import usePreviousState from '../../hooks/usePreviousState';
import { useEffect, useReducer } from 'react';
import InputCache from '../../types/enrollments/InputCache';
import errorsReducer from '../../reducers/enrollments/errorsReducer';
import ErrorsReducerAction from '../../types/enrollments/ErrorsReducerAction';
import ErrorsState from '../../types/enrollments/ErrorsState';
import FieldsState from '../../types/enrollments/FieldState';
import fetchErrors from '../../utils/enrollments/fetchErrors';
import fieldsHaveChanged from '../../utils/enrollments/fieldsHaveChanged';
import { newInputsCache } from './useInputsCache';
import useDebouncedFetch from '../useDebouncedFetch';

const initState = (errors: ErrorsState): ErrorsState => {
  return (
    Object.entries(errors).reduce((obj, [key, errorsArray]) => {
      if (key === 'enrollment') {
        obj['enrollment'] = errorsArray;
      } else {
        if (errorsArray.length === 0) {
          obj[key] = [{}];
        } else {
          obj[key] = errorsArray;
        }
      }

      return obj;
    }, {} as ErrorsState)
  );
};

const useErrors = (
  fields: FieldsState,
  _inputsCache: InputCache,
  initialErrors: ErrorsState,
): [errors: ErrorsState, errorsDispatch: (action: ErrorsReducerAction) => void] => {
  const [errors, errorsDispatch] = useReducer(
    errorsReducer,
    initialErrors,
    initState
  );

  const debouncedFetch = useDebouncedFetch();

  const previousFields = usePreviousState(fields) as Omit<FieldsState, 'enrollment'>;

  useEffect(() => {
    const { enrollment_practices, enrollment_contacts, enrollment_doctors } =
      fields;

    const resourceFields = {
      enrollment_practices,
      enrollment_contacts,
      enrollment_doctors
    };

    const fieldsChanged = fieldsHaveChanged(previousFields, resourceFields);

    if (fieldsChanged) {
      debouncedFetch(() => {
        fetchErrors(fields, newInputsCache(fields), errorsDispatch);
      });
    }
  }, [fields]);

  useEffect(() => {
    fetchErrors(fields, newInputsCache(fields), errorsDispatch);
  }, []);

  return [errors, errorsDispatch];
};

export default useErrors;
