import EnrollmentPractice from './EnrollmentPractice';

export type DefaultEnrollmentPractice = {
  [key in keyof EnrollmentPractice]: key extends 'accept' ? string | boolean : string;
} & {
  accept: string | boolean;
}

export const DEFAULT_PRACTICE = {
  address: undefined,
  practice_name: undefined,
  program: undefined,
  accept: false,
  npi: undefined,
  tax_id: undefined,
  phone_number: undefined,
  fax_number: undefined,
  timezone: undefined,
  street: undefined,
  suite: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
  lat: undefined,
  lng: undefined,
  active: true
};
