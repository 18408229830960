import { useEffect, useRef } from 'react';

const usePreviousState = (state: unknown): unknown => {
  const stateRef = useRef();

  useEffect(() => {
    (stateRef.current as unknown) = state;
  }, [state]);

  return stateRef.current;
};

export default usePreviousState;
