import React, { useState, Fragment } from 'react';
import faker from 'faker';
import NpiSummary from './NpiSummary';

const NPI_SUMMARIES = {
  '1234567890': {
    provider_organization_name: faker.company.companyName(),
    first_name: faker.name.firstName(),
    healthcare_provider_taxonomy_code_1: faker.git.commitSha(),
    entity_type_code: faker.git.commitSha(),
    provider_license_number_1: faker.git.commitSha(),
    provider_business_practice_location_address_telephone_number: faker.phone.phoneNumber(),
    provider_business_practice_location_address_fax_number: faker.phone.phoneNumber(),
    provider_first_line_business_practice_location_address: faker.address.streetAddress(),
    provider_second_line_business_practice_location_address: 'Apt 2F',
    provider_business_practice_location_address_city_name: faker.address.city(),
    provider_business_practice_location_address_state_name: faker.address.stateAbbr(),
    provider_business_practice_location_address_postal_code: faker.address.zipCode(),
  },
  '1111111111': {
    provider_organization_name: faker.company.companyName(),
    first_name: faker.name.firstName(),
    healthcare_provider_taxonomy_code_1: faker.git.commitSha(),
    entity_type_code: faker.git.commitSha(),
    provider_license_number_1: faker.git.commitSha(),
    provider_business_practice_location_address_telephone_number: faker.phone.phoneNumber(),
    provider_business_practice_location_address_fax_number: faker.phone.phoneNumber(),
    provider_first_line_business_practice_location_address: faker.address.streetAddress(),
    provider_second_line_business_practice_location_address: 'Apt 2F',
    provider_business_practice_location_address_city_name: faker.address.city(),
    provider_business_practice_location_address_state_name: faker.address.stateAbbr(),
    provider_business_practice_location_address_postal_code: faker.address.zipCode(),
  }
};

const Demo = () => {
  const [npi, setNpi] = useState('');
  const [npiSummary, setNpiSummary] = useState(null);
  const [npiValues, setNpiValues] = useState(null);

  const useNpiValues = () => {
    setNpiValues(npiSummary);
  };

  const handleSetNpiSummary = () => {
    setNpiSummary(NPI_SUMMARIES[npi]);
  };

  return (
    <Fragment>
      <h1>Current Npi</h1>
      <div style={{ backgroundColor: 'white', color: 'black', marginBottom: '20px' }}>
        {
          npiValues && Object.keys(npiValues).map((key, index) => (
            <div key={index}>{key}: {npiSummary[key]}</div>
          ))
        }
        <input type='text' onChange={e => { setNpi(e.target.value); }} />
      </div>
      <NpiSummary
        npi={npi}
        useNpiValues={useNpiValues}
        npiSummary={npiSummary}
        setNpiSummary={handleSetNpiSummary}
      />
    </Fragment>
  );
};

export default Demo;