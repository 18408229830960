import EnrollmentError from '../../types/enrollments/EnrollmentError';
import InputCache from '../../types/enrollments/InputCache';
import ResourceName from '../../types/enrollments/ResourceName';
import ErrorsReducerAction from '../../types/enrollments/ErrorsReducerAction';
import FieldsState from '../../types/enrollments/FieldState';

const fetchErrors = async (
  fields: FieldsState,
  inputCache: InputCache,
  errorsDispatch: (action: ErrorsReducerAction) => void
): Promise<void> => {
  const {
    enrollment,
    enrollment_practices,
    enrollment_contacts,
    enrollment_doctors
  } = fields;
  const { id } = enrollment;

  const csrf = (document.querySelector('meta[name=csrf-token]') as HTMLMetaElement).content;

  const url = `/enrollment_validations/${id ? id : ''}`;
  const method = id ? 'PATCH' : 'POST';

  const resp = await fetch(url, {
    method,
    body: JSON.stringify({
      enrollment_practices_attributes: enrollment_practices,
      enrollment_contacts_attributes: enrollment_contacts,
      enrollment_doctors_attributes: enrollment_doctors,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrf
    }
  });

  const json = await resp.json();

  const {
    enrollment_practices_errors,
    enrollment_contacts_errors,
    enrollment_doctors_errors,
    enrollment_errors
  } = json;

  const filterUneditedInputs = (
    errors: EnrollmentError[] = [],
    resourceName: ResourceName
  ): EnrollmentError[] => {
    return(
      errors.reduce((newErrors, error, index) => {
        const cache = inputCache[resourceName][index];

        if (cache) {
          const attributes = Object.keys(inputCache[resourceName][index]);
          const editedAttributes = attributes.filter(attribute => (
            cache[attribute] === true
          ));

          newErrors.push(
            editedAttributes.reduce((newError, attribute) => {
              if (error[attribute]) newError[attribute] = error[attribute];

              return newError;
            }, {} as EnrollmentError)
          );
        }

        return newErrors;
      }, [] as EnrollmentError[])
    );
  };

  errorsDispatch({
    type: 'setAll',
    allNewErrors: {
      enrollment_practices_errors: filterUneditedInputs(
        enrollment_practices_errors,
        'enrollment_practices'
      ),
      enrollment_contacts_errors: filterUneditedInputs(
        enrollment_contacts_errors,
        'enrollment_contacts'
      ),
      enrollment_doctors_errors: filterUneditedInputs(
        enrollment_doctors_errors,
        'enrollment_doctors'
      ),
      enrollment_errors: enrollment_errors,
    }
  });
};

export default fetchErrors;
