import Col from 'components/Bootstrap/Col';
import Row from 'components/Bootstrap/Row';
import RefTaxonomyDropDown from 'components/RefTaxonomyDropDown/RefTaxonomyDropDown';
import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import Doctor from 'types/Doctor';
import RefTaxonomy from 'types/enrollments/RefTaxonomy';
import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import CaseFormInput from './CaseFormInput';
import NumberForm from 'components/faxes_ins_cases/New/NumberForm';
import {isRequiredValidation} from '../../../utils/validations';


type DoctorFormProps =
  & ResourceForm
  & {
    doctor?: Doctor;
    attr_hash?: any;
  }

const DoctorForm: FC<DoctorFormProps> =
  ({ doctor = {} as Doctor, onChange, visible, attr_hash }) => {
    const typeProps = visible ? {} : { type: 'hidden' };
    const disabled = Boolean(doctor?.id);
    const readonly = doctor?.id ? { readonly: 'readonly'} : {};

    const inputProps = {
      resource: doctor,
      onChange,
      attr_hash,
      nestedResourceName: 'doctor',
      ...typeProps,
      ...readonly
    };

    const handleRefTaxonomyChange = (refTaxonomy: RefTaxonomy) => {
      const event = {
        target: { value: String(refTaxonomy.id) }
      } as ChangeEvent<HTMLInputElement>;
      onChange(event, 'ref_taxonomy_id');
    };

    return (
      <>
        <Row>
          <Col sm={6}>
            <CaseFormInput
              label='NPI'
              attribute='npi'
              required={isRequiredValidation('globaldoctor','npi',{globaldoctor: attr_hash})}
              {...inputProps}
            />
          </Col>
          <Col sm={6}>
            <CaseFormInput
              label='Specialty'
              attribute='ref_taxonomy_id'
              {...inputProps}
              type='select'
              hidden={!visible}
              value={String(doctor.ref_taxonomy_id)}
            >
              <RefTaxonomyDropDown
                value={doctor.ref_taxonomy_id}
                onChange={handleRefTaxonomyChange}
                disabled={disabled}
              />
            </CaseFormInput>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <CaseFormInput
              label='First Name'
              attribute='firstname'
              required={isRequiredValidation('globaldoctor','firstname',{globaldoctor: attr_hash})}
              {...inputProps}
            />
          </Col>
          <Col sm={3}>
            <CaseFormInput
              label='Middle Name'
              attribute='middlename'
              {...inputProps}
            />
          </Col>
          <Col sm={3}>
            <CaseFormInput
              label='Last Name'
              attribute='lastname'
              required={isRequiredValidation('globaldoctor','lastname',{globaldoctor: attr_hash})}
              {...inputProps}
            />
          </Col>
          <Col sm={3}>
            <CaseFormInput label='Tax ID' attribute='tax_id' {...inputProps} />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <CaseFormInput label='DEA' attribute='dea' {...inputProps} />
          </Col>
          <Col sm={3}>
            <CaseFormInput label='PTAN' attribute='ptan' {...inputProps} />
          </Col>
          <Col sm={3}>
            <CaseFormInput label='Title' attribute='title' {...inputProps} />
          </Col>
          <Col sm={3}>
            <CaseFormInput label='SLN' attribute='sln' {...inputProps} />
          </Col>
        </Row>

        <NumberForm
          resource={doctor}
          onChange={onChange}
          visible={visible}
          resourceName={'doctor'}
          disabled={disabled}
        />
      </>
    );
  };

export default DoctorForm;
