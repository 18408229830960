import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import Col from 'components/Bootstrap/Col';
import Select2 from 'components/Select2/Select2';
import Input from 'components/Input';
import IdableResource from 'types/IdableResource';
import MultiSelectAttributeForm from './MultiSelectAttributeForm';

type DynamicAttributesFormProps = ResourceForm & {
  resource?: any;
  attr_hash?: any;
  attributes_of: string;
};


const DynamicAttributesForm: FC<DynamicAttributesFormProps> = ({
  resource,
  attr_hash,
  attributes_of,
}) => {
  const [newAttributeHash, setNewAttributeHash] = useState<any[]>(attr_hash);

  const handleDynamicFieldName = (name: string) => {
    return `case[${attributes_of}_attributes][${name}]`;
  };

  const handleDynamicFieldId = (name: string) => {
    return `case_${attributes_of}_attributes__${name}`;
  };

  const handleSelectAttributeFields = (name: string, value: string) => {
    setNewAttributeHash((newAttributeHash) => {
      const newState = newAttributeHash?.map((obj) => {
        if (obj.field_name === name) {
          if (obj.field_type === 'select') {
            return {
              ...obj,
              selected: value,
            };
          }
          if (obj.field_type === 'multi_select') {

            // Check if the value is already in the 'selected' array
            const isValueSelected = obj.selected.includes(value);

            if (isValueSelected) {
              // If the value is already selected, remove it
              let object ={
                ...obj,
                selected: obj.selected.filter((item) => item !== value),
              };
              return object;

            } else {
              // If the value is not selected, add it
              let object ={
                ...obj,
                selected: [...obj.selected, value],
              };

            return object
            }

          }


        }
        return obj;
      });
      return newState;
    });
  };

  const handleMultiSelectAttributeFields =(e: any) =>{
  }
  const handleNewAttributeFields = (
    name: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    e.persist();
    setNewAttributeHash((newAttributeHash) => {
      const newState = newAttributeHash?.map((obj) => {
        if (obj.field_name === name) {
          if (obj.field_type == 'checkbox') {
            return {
              ...obj,
              field_values: [toggleBooleanString(obj.field_values[0])],
            };
          } else {
            return {
              ...obj,
              field_values: [e.target.value],
            };
          }
        }
        return obj;
      });
      return newState;
    });
  };

  const toggleBooleanString = (value: string) => {
    if (value === 'true' || value ==='1' ) {
      return 'false';
    } else if (value === 'false' || '0') {
      return 'true';
    } else {
      throw new Error(
        'Invalid input. Only "true" or "false" strings are allowed.'
      );
    }
  };

  // will update attribute field values according to resource attributes
  useEffect(() => {
    if (resource?.id){
      if (attributes_of == 'practice'){
        handlePracticeAttributeFields();
      }else if (attributes_of == 'patient') {
        handleGlobalPatientAttributeFields();
      }
     }
   }, [resource]);

  const handlePracticeAttributeFields = ()=>{
    resource?.practice_attributes?.forEach((obj) => {
        handleStateForGivenObject(obj);
      });
  }

  const handleGlobalPatientAttributeFields = ()=>{
    resource?.global_patient_attributes?.forEach((obj) => {
      handleStateForGivenObject(obj);
    });
  }

  const handleStateForGivenObject = (obj1)=>{
    setNewAttributeHash((newAttributeHash) => {
      return newAttributeHash?.map((obj) => {
        if (obj.field_name === obj1.name) {
          if (obj.field_type === 'checkbox') {
            return {
              ...obj,
              field_values: [
                  toggleBooleanString(toggleBooleanString(obj1.value)),
              ],
            };
          } else if (obj.field_type === 'select'){
            return {
              ...obj,
              selected: (obj1.value),
            };
          }else{
            return {
              ...obj,
              field_values: [obj1.value],
            };
          }
        }
        return obj;
      });
    });
  }

  const isFieldPresentInAttributes = (name: string ) => {
    let result = false;
    if (attributes_of == 'practice'){
      resource?.practice_attributes?.forEach((obj1) => {
        if (obj1.name === name) {
          result = true;
        }
      });
    }else if (attributes_of == 'patient')
      resource.global_patient_attributes.forEach((obj1) => {
        if (obj1.name === name) {
          result = true;
        }
      });
    return result;
  };

  const handleDisableSelectFields = (name: string) => {
    if (
      ['practice', 'patient'].includes(attributes_of) &&
      resource?.id
    ) {
      return isFieldPresentInAttributes(name);
    }
    return false;
  };

  const handleDisableFields = (name: string) => {
    if (
      ['practice', 'patient'].includes(attributes_of) &&
      resource?.id
    ) {
      if (isFieldPresentInAttributes(name)) {
        return true;
      } else {
        return '';
      }
    }
    return '';
  };

  const  formatString =(input: string)=> {
  // Split the input string using underscores
  let words = input.split("_");

  // Capitalize the first letter of each word
  let formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back together with spaces
  let formattedString = formattedWords.join(" ");

  return formattedString;
}



  const stringToBoolean = (str: string) => {
    return str === 'true';
  };

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const selectPlaceHolder = (str: string) => {
    return `Please select ${str}`;
  };


  return (
    <>
      <div className="d-flex flex-wrap">
        {newAttributeHash?.map((item, index) =>
          item['field_type'] == 'select' ? (
            <Col sm={6}>
              <Input
                key={item['field_name']}
                label={formatString(item['field_name'])}
                id={handleDynamicFieldId(item['field_name'])}
                type={'hidden'}
                name={handleDynamicFieldName(item['field_name'])}
                value={item['selected']}
              />

              <label>{capitalizeFirst(item['field_name'])}</label>
              <Select2
                disabled={handleDisableSelectFields(item['field_name'])}
                value={item['selected']}
                placeholder={selectPlaceHolder(
                  capitalizeFirst(item['field_name'])
                )}
              >
                {item['field_values'].map((field_value: string) => {
                  return (
                    <Select2.Option
                      key={field_value}
                      onClick={() => {
                        handleSelectAttributeFields(
                          item['field_name'],
                          field_value
                        );
                      }}
                    >
                      {field_value}
                    </Select2.Option>
                  );
                })}
              </Select2>
            </Col>
          ): item['field_type'] == 'multi_select' ? (
            <MultiSelectAttributeForm
              item={item}
              handleDynamicFieldId={handleDynamicFieldId}
              handleDynamicFieldName= {handleDynamicFieldName}
              capitalizeFirst={formatString}
              onChange={handleMultiSelectAttributeFields}
              handleDisableSelectFields={handleDisableSelectFields}
              selectPlaceHolder={selectPlaceHolder}
              handleSelectAttributeFields={handleSelectAttributeFields}

              />

          ) : item['field_type'] == 'text_field'? (
            <Col sm={6}>
              <Input
                disabled={`${handleDisableFields(item['field_name'])}`}
                key={item['field_name']}
                label={formatString(item['field_name'])}
                id={handleDynamicFieldId(item['field_name'])}
                type={item['field_type']}
                onChange={(event) => {
                  handleNewAttributeFields(item['field_name'], event);
                }}
                name={handleDynamicFieldName(item['field_name'])}
                value={item['field_values']}
                className="form-control"
              />
            </Col>
          ): item['field_type'] == 'text_area' ? (
            <Col sm={12}>
              <Input
                disabled={`${handleDisableFields(item['field_name'])}`}
                key={item['field_name']}
                label={formatString(item['field_name'])}
                id={handleDynamicFieldId(item['field_name'])}
                type={item['field_type']}
                onChange={(event) => {
                  handleNewAttributeFields(item['field_name'], event);
                }}
                name={handleDynamicFieldName(item['field_name'])}
                value={item['field_values']}
                className="form-control"
              />
            </Col>
          ) : item['field_type'] == 'checkbox' ? (
            <Col sm={6}>
              <Input
                disabled={`${handleDisableFields(item['field_name'])}`}
                key={item['field_name']}
                label={formatString(item['field_name'])}
                id={handleDynamicFieldId(item['field_name'])}
                type={item['field_type']}
                onChange={(event) => {
                  handleNewAttributeFields(item['field_name'], event);
                }}
                name={handleDynamicFieldName(item['field_name'])}
                checked={stringToBoolean(item['field_values'][0])}
                value={item['field_values']}
                className="form-control"
              />
            </Col>
          ) : item['field_type'] == 'date' ? (
            <Col sm={6}>
              <Input
                disabled={`${handleDisableFields(item['field_name'])}`}
                key={item['field_name']}
                label={formatString(item['field_name'])}
                id={handleDynamicFieldId(item['field_name'])}
                type={item['field_type']}
                onChange={(event) => {
                  handleNewAttributeFields(item['field_name'], event);
                }}
                name={handleDynamicFieldName(item['field_name'])}
                value={item['field_values']}
                className="form-control"
              />
            </Col>
          ) : (
            ''
          )
        )}
      </div>
    </>
  );
};

export default DynamicAttributesForm;
