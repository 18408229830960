// eslint-disable-next-line quotes
import { FunctionComponent, memo, NamedExoticComponent } from 'react';
import EnrollmentError from 'types/enrollments/EnrollmentError';

type HasErrors = {
  errors: EnrollmentError[];
};

const errorsAreEqual = (
  { errors: prevErrors, ...prevProps }: HasErrors,
  { errors, ...props }: HasErrors
) => {
  const errorsHaveChanged = 
  (prevErrors.length !== errors.length) ||
    !(errors as EnrollmentError[])
      .every((error, errorIndex) => {
        const prevError = prevErrors[errorIndex];

        if (Object.entries(error).length !== Object.entries(prevError).length) {
          return false;
        }

        return Object.entries(error).every(([key, messages]) => {
          return (
            messages
              .every((message, index) => { 
                return (
                  message === (
                    prevError[key] && prevError[key] && prevError[key][index]
                  )
                );
              })
          );
        });
      });
  
  if (errorsHaveChanged) return false;

  return Object.entries(props).every(([key, value]) => {
    return Object.is(value, prevProps[key]);
  });
};

const MemoizedResourceCard = (
  Component: FunctionComponent<any>
): NamedExoticComponent<any> => {
  return memo(Component, errorsAreEqual);
};

export default MemoizedResourceCard;
