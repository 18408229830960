import { useCallback, useState } from 'react';
import EnrollmentDoctor from 'types/enrollments/EnrollmentDoctor';
import EnrollmentPractice from 'types/enrollments/EnrollmentPractice';
import NpiSummaryType from 'types/NpiSummary';
import NpiSummary from 'types/NpiSummary';

export type NpiSummaryCache = {
  [npi: number]: NpiSummary
}

export type GetNpiSummary = (
  resource: (EnrollmentPractice | EnrollmentDoctor)
) => Promise<void>;

type UseNpiSummariesTuple = [NpiSummaryCache, GetNpiSummary]

const useNpiSummaries = (
  initialNpiSummaries: NpiSummaryCache
): UseNpiSummariesTuple => {
  const [npiSummaries, setNpiSummaries] =
    useState<NpiSummaryCache>(initialNpiSummaries);

  const getNpiSummary: GetNpiSummary = useCallback(async (resource) => {
    const { npi } = resource;

    if (npi && !npiSummaries[npi]) {
      const resp = await fetch(`/ref_npis/${npi}`);
      const json = await resp.json();

      const {
        provider_organization_name: practice_name,
        provider_first_name: firstname,
        provider_middle_name: middlename,
        provider_last_name: lastname,
        healthcare_provider_taxonomy_code_1: taxonomy,
        entity_type_code: type,
        provider_license_number_1: sln,
        provider_business_practice_location_address_telephone_number: phone_number,
        provider_business_practice_location_address_fax_number: fax_number,
        provider_first_line_business_practice_location_address: street,
        provider_second_line_business_practice_location_address: suite,
        provider_business_practice_location_address_city_name: city,
        provider_business_practice_location_address_state_name: state,
        provider_business_practice_location_address_postal_code: zip
      } = json;

      const npiSummary: NpiSummaryType = {
        practice_name,
        firstname,
        middlename,
        lastname,
        taxonomy,
        type,
        sln,
        phone_number,
        fax_number,
        street,
        suite,
        city,
        state,
        zip,
        npi
      };

      return (
        setNpiSummaries({
          ...npiSummaries,
          [npi]: npiSummary
        })
      );
    }
  }, [npiSummaries]);

  return [npiSummaries, getNpiSummary] as UseNpiSummariesTuple;
};

export default useNpiSummaries;
