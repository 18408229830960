import React, { FC } from 'react';
import BootstrapType from 'types/Bootstrap/BootstrapType';
import bootstrapClassName from 'utils/Bootstrap/boostrapClassName';

const Col: FC<BootstrapType> = ({ children, sm, md, ...props }) => {
  let className = 'col';
  
  if (sm) { className += `-sm-${sm}`; }
  if (md) { className += `-md-${md}`; }

  return (
    <div className={bootstrapClassName(className, [], props)}>
      {children}
    </div>
  );
};

export default Col;
