import Col from 'components/Bootstrap/Col';
import Row from 'components/Bootstrap/Row';
import React, {FC, useEffect, useState} from 'react';
import Practice from 'types/Practice';
import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import CaseFormInput from './CaseFormInput';
import ResourceNumber from 'types/ResourceNumber';
import AddressForm from 'components/faxes_ins_cases/New/AddressForm';
import NumberForm from 'components/faxes_ins_cases/New/NumberForm';
import {isRequiredValidation} from 'utils/validations';
import DynamicAttributesForm from '../DynamicAttributesForm';

type PracticeFormProps = ResourceForm & {
  practice?: Practice;
  attr_hash?: any;
  practice_attributes?: any;
};

const PracticeForm: FC<PracticeFormProps> =
  ({ practice = {} as Practice, visible, onChange, attr_hash, practice_attributes }) => {
    const typeProps = visible ? {} : { type: 'hidden' };
    const disabled = Boolean(practice?.id);
    const readonly = practice?.id ? { readonly: 'readonly'} : {};
    const [phoneNumber, setPhoneNumber] = useState<ResourceNumber>({number: ''});
    const [faxNumber, setFaxNumber] = useState<ResourceNumber>({number: ''});

    const inputProps = {
      resource: practice,
      onChange,
      attr_hash,
      nestedResourceName: 'practice',
      ...typeProps,
      ...readonly
    };

    return (
      <>
        <Row>
          <Col sm={4}>
            <CaseFormInput
              label="NPI"
              attribute="npi"
              value={`${practice.npi}`}
              required={isRequiredValidation('practice', 'npi', {
                practice: attr_hash,
              })}
              {...inputProps}
            />
          </Col>
          <Col sm={4}>
            <CaseFormInput
              label="Name"
              attribute="name"
              required={isRequiredValidation('practice', 'name', {
                practice: attr_hash,
              })}
              {...inputProps}
            />
          </Col>
          <Col sm={4}>
            <CaseFormInput label="Tax ID" attribute="tax_id" {...inputProps} />
          </Col>
        </Row>

        <AddressForm
          resource={practice}
          onChange={onChange}
          visible={visible}
          disabled={disabled}
          resourceName={'practice'}
        />
        <NumberForm
          resource={practice}
          onChange={onChange}
          visible={visible}
          disabled={disabled}
          resourceName={'practice'}
        />
        <DynamicAttributesForm
          resource={practice}
          attributes_of={'practice'}
          attr_hash={practice_attributes}
          onChange={onChange}
        />
      </>
    );
};

export default PracticeForm;
