import React, { FC, useState } from 'react';
import Select2 from './Select2';
import faker from 'faker';

const SELECTIONS = [];

for (let id = 1; id < 100; id++) {
  SELECTIONS.push({
    id,
    name: faker.company.catchPhrase()
  });
}

const Demo: FC = () => {
  const [selected, setSelected] = useState(SELECTIONS[0]);
  const [searchSelected, setSearchSelected] = useState(SELECTIONS[0]);
  
  return (
    <div>
      <h1>Without Search</h1>
      <Select2 value={selected.name}>
        {
          SELECTIONS.map((selection, index) => (
            <Select2.Option key={index} onClick={() => setSelected(selection)}>
              {selection.name}
            </Select2.Option>
          ))
        }
      </Select2>

      <h1>With Search</h1>
      <Select2 value={searchSelected.name}>
        {
          SELECTIONS.map((selection, index) => (
            <Select2.Option key={index} onClick={() => setSearchSelected(selection)}>
              {selection.name}
            </Select2.Option>
          ))
        }
      </Select2>
    </div>
  );
};

export default Demo;
