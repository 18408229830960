import EnrollmentDoctor from './EnrollmentDoctor';

export const DEFAULT_DOCTOR = {
  firstname: undefined,
  middlename: undefined,
  lastname: undefined,
  specialty: undefined,
  npi: undefined,
  tax_id: undefined,
  dea: undefined,
  ptan: undefined,
  phone_number: undefined,
  fax_number: undefined,
  active: true,
  accept: true,
};

export type DefaultEnrollmentDoctor = {
  firstname: undefined,
  middlename: undefined,
  lastname: undefined,
  specialty: undefined,
  npi: undefined,
  tax_id: undefined,
  dea: undefined,
  ptan: undefined,
  phone_number: undefined,
  fax_number: undefined,
  active: boolean,
  accept: boolean,
}
