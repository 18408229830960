const removeNullFromObject = (obj) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object'){
      removeNullFromObject(obj[key]);
    }else if (obj[key] === null){
      obj[key] = '';
    }
  });
  return obj;
};

export default removeNullFromObject;