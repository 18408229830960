import { snakeCase } from 'lodash';

interface RailsNestedResource {
  id: string;
  name: string;
}

const railsNestedResource = (
  parentResourceName: string,
  resourceName: string,
  index: number | null,
  attribute: string,
): RailsNestedResource => {
  const parts = [
    snakeCase(parentResourceName),
    `${snakeCase(resourceName)}_attributes`,
    index,
    attribute === '_destroy' ? attribute: snakeCase(attribute)
  ];

  const name = () => {
    const [parentResourceName, resourceName, index, attribute] = parts;

    if (index !== null) {
      return `${parentResourceName}[${resourceName}][${index}][${attribute}]`;
    } else {
      return `${parentResourceName}[${resourceName}][${attribute}]`;
    }
  };

  const id = () => parts.join('_');

  return ({
    id: id(),
    name: name()
  });
};

export default railsNestedResource;
