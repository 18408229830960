import EnrollmentError from './EnrollmentError';

export const DEFAULT_USER = {
  firstname: undefined,
  middlename: undefined,
  lastname: undefined,
  email: undefined,
  active: true,
  accept: true
};

export type DefaultEnrollmentContact = {
  firstname: undefined,
  middlename: undefined,
  lastname: undefined,
  email: undefined,
  active: true,
  accept: true
}
