import React, { memo, useEffect, useState } from 'react';
import Paths from '../../types/Paths';
import ResourcesSummary from './ResourcesSummary';

const isValidToFetch = (email) => {
  return email?.match(/[^@;]+@[^@]+\.[^@]+/);
};

const PossibleExistingUsers = ({ user }) => {
  const [possibleUsers, setPossibleUsers] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [cache, setCache] = useState({});

  useEffect(() => {
    const { email } = user;

    const fetchPossibleUsers = async (email) => {
      let queryParams = '';

      const addToParams = value => {
        if (queryParams.length > 0) queryParams += '&';

        queryParams += value;
      };

      if (email) addToParams(`email=${email}`);

      if (cache[email]) {
        setPossibleUsers(cache[email]);
      } else {
        const resp = await fetch(`/matching_users?${queryParams}`);

        const json = await resp.json();

        setPossibleUsers(json);
        setCache({
          ...cache,
          [email]: json
        });

      }
    };

    if (shouldFetch && isValidToFetch(email)) {
      fetchPossibleUsers(email);
    }
  }, [shouldFetch]);

  useEffect(() => {
    const { email } = user;

    if (shouldFetch && isValidToFetch(email)) setShouldFetch(false);

    const timer = setTimeout(() => { setShouldFetch(true); }, 500);

    return () => clearTimeout(timer);
  }, [user]);

  return (
    <ResourcesSummary>
      <ResourcesSummary.Title>Possible Existing Users</ResourcesSummary.Title>
      <ResourcesSummary.Header
        headers={['ID', 'Firstname', 'LastName', 'Email']}
      />
      <ResourcesSummary.Body
        resourcesPath={Paths.CONTACT}
        resources={possibleUsers}
        attributes={['id', 'firstname', 'lastname', 'email']}
      />
    </ResourcesSummary>
  );
};

const shouldNotRerender = (prevProps, props) => {
  return prevProps?.user?.email === props?.user?.email;
};

const MemoizedPossibleExistingUsers = memo(PossibleExistingUsers, shouldNotRerender);

export default MemoizedPossibleExistingUsers;
