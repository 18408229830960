import EnrollmentResource from 'types/enrollments/EnrollmentResource';
import { DEFAULT_USER } from '../../types/enrollments/Contact';
import { DEFAULT_DOCTOR } from '../../types/enrollments/Doctor';
import FieldsReducer from '../../types/enrollments/FieldsReducer';
import FieldsState from '../../types/enrollments/FieldState';
import { DEFAULT_PRACTICE } from '../../types/enrollments/Practice';

const resourceNameToDefault = {
  enrollment_practices: DEFAULT_PRACTICE,
  enrollment_contacts: DEFAULT_USER,
  enrollment_doctors: DEFAULT_DOCTOR
};

const updateResource = (slice: EnrollmentResource[], index: number, callback) => {
  return slice.map((resource, resourceIndex) => {
    if (index === resourceIndex) {
      return callback(resource);
    } else {
      return resource;
    }
  }); 
};

const removeResource = (slice: EnrollmentResource[], index: number, DEFAULT_RESOURCE) => {
  const newSlice = slice.reduce((array, resource, resourceIndex) => {
    if (index === resourceIndex && resource.id) {
      array.push({
        ...resource,
        _destroy: true
      });
    }

    if (index !== resourceIndex) array.push(resource);

    return array;
  }, []);

  if (newSlice.length > 0) {
    return newSlice;
  } else {
    return [DEFAULT_RESOURCE];
  }
};

const setResource = (slice: EnrollmentResource[], index: number, values) => {
  return updateResource(slice, index, () => values);
};

export const initState = ({ enrollment, ...fields }: FieldsState): FieldsState => {
  return (
    Object.keys(resourceNameToDefault).reduce((obj, resourceName) => {
      const resources = fields[resourceName];

      if (!resources || resources.length === 0) {
        obj[resourceName] = [resourceNameToDefault[resourceName]];
      } else {
        obj[resourceName] = resources;
      }

      return obj;
    }, { enrollment } as FieldsState)
  );
};

const fieldsReducer: FieldsReducer = (state, action): FieldsState => {
  const slice = state[action.resourceName] as EnrollmentResource[];

  switch (action.type) {
  case 'change':
    return {
      ...state,
      [action.resourceName]:
        updateResource(
          slice,
          action.index,
          (resource: EnrollmentResource) => {
            let value: string | boolean;

            if (action.event.target.type === 'checkbox') {
              value = action.event.target.checked;
            } else {
              value = action.event.target.value;
            }

            return { ...resource, [action.attribute]: value };
          }
        )
    };
  case 'add':
    return {
      ...state,
      [action.resourceName]:
        slice.concat([
          resourceNameToDefault[action.resourceName]
        ] as EnrollmentResource[])
    };
  case 'remove':
    return {
      ...state,
      [action.resourceName]: removeResource(
        slice,
        action.index,
        resourceNameToDefault[action.resourceName]
      )
    };
  case 'setWithNpiValues':
    return {
      ...state,
      [action.resourceName]: updateResource(
        slice,
        action.index,
        (resource: EnrollmentResource) => ({
          ...resource,
          ...action.npiValues
        })
      )
    };
  case 'setWithPracticeValues':
    return {
      ...state,
      [action.resourceName]: setResource(
        slice,
        action.index,
        action.practiceValues
      )
    };
  default:
    throw new Error();
  }
};

export default fieldsReducer;
