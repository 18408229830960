import EnrollmentResource from 'types/enrollments/EnrollmentResource';
import FieldsState from 'types/enrollments/FieldState';
import resourceHasChanged from './resourceHasChanged';

type FieldStateWithoutEnrollment = Omit<FieldsState, 'enrollment'>;

const fieldsHaveChanged =
  (prev: FieldStateWithoutEnrollment, curr: FieldStateWithoutEnrollment): boolean => {
    if (!prev) return false; 

    return (
      Boolean(
        Object.entries(curr).find(([key, resources]) => {
          return (
            (resources as EnrollmentResource[]).find((resource, index) => {
              const previousResource = prev[key][index];

              return resourceHasChanged(previousResource, resource);
            })
          );
        })
      )
    );
  };

export default fieldsHaveChanged;
