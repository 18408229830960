import ErrorsReducerAction from '../../types/enrollments/ErrorsReducerAction';
import ErrorsState from '../../types/enrollments/ErrorsState';

const errorsReducer = (state: ErrorsState, action: ErrorsReducerAction): ErrorsState => {
  switch (action.type) {
  case 'set':
    return {
      ...state,
      [action.resourceName]: action.newErrors
    };
  case 'setAll':
    return action.allNewErrors;
  default:
    throw new Error();
  }
};

export default errorsReducer;
