import useDebouncedFetch from '../../hooks/useDebouncedFetch';
import { useEffect, useState } from 'react';
import EnrollmentPractice from '../../types/enrollments/EnrollmentPractice';
import { DefaultEnrollmentPractice } from '../../types/enrollments/Practice';
import usePreviousState from '../usePreviousState';

const usePossibleExistingPractices = (
  practice: (EnrollmentPractice | DefaultEnrollmentPractice)
): EnrollmentPractice[] => {
  const [possiblePractices, setPossiblePractices] = useState([]);
  const previousPractice = usePreviousState(practice) as EnrollmentPractice;
  const debouncedFetch = useDebouncedFetch();

  useEffect(() => {
    const fetchPossiblePractices = async (street, phone_number, npi) => {
      let queryParams = '';

      const addToParams = value => {
        if (queryParams.length > 0) queryParams += '&';

        queryParams += value;
      };

      if (street) addToParams(`address=${street}`);
      if (phone_number) addToParams(`phone_number=${phone_number}`);
      if (npi) addToParams(`npi=${npi}`);

      const resp = await fetch(`/matching_practices?${queryParams}`);

      const json = await resp.json();

      setPossiblePractices(json);
    };

    if (practice) {
      const { street, phone_number, npi } = practice;

      const isSearchable = (string: string | undefined): boolean => (
        ((string?.split(' ')?.length || 0) > 1)
      );

      const wasPreviouslySearchable = () => isSearchable(
        (<EnrollmentPractice>previousPractice)?.street
      );

      const shouldFetchPossibleExistingPractices =
        isSearchable(street) || wasPreviouslySearchable();

      if (shouldFetchPossibleExistingPractices) {
        debouncedFetch(() => {
          fetchPossiblePractices(street, phone_number, npi);
        });
      }
    }
  }, [practice]);

  return possiblePractices;
};


export default usePossibleExistingPractices;
