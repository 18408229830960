import { useEffect, useState } from 'react';
import useDebouncedFetch from './useDebouncedFetch';

export type OnScrollBottom = () => void;
export type OnUpdateSearchQuery = (searchQuery: string) => void;

type UseSelect = (
  url: string,
  options?: {
    defaultLimit?: number;
    defaultOffset?: number;
    debounceTimeout?: number;
  }
) => (OnScrollBottom | OnUpdateSearchQuery | unknown[])[]; 

const useSelect: UseSelect = (url, {
  defaultLimit = 50,
  defaultOffset = 0,
} = {}
) => {
  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState(defaultOffset);
  const [searchQuery, setSearchQuery] = useState('');
  const [collection, setCollection] = useState([]);

  const fetchUrl = () =>
    `${url}.json?limit=${limit}&offset=${offset}&query=${searchQuery}`;

  const fetchData = async (cb) => {
    const resp = await fetch(fetchUrl());
    const data = resp.ok ? await resp.json() : [];

    cb(data);
  };

  const onScrollBottom = () => {
    setOffset(offset + 50);
  };

  const updateSearchQuery = (query: string) => {
    setSearchQuery(query);
  };

  const debouncedFetch = useDebouncedFetch();

  useEffect(() => {
    if (url) {
      debouncedFetch(() => {
        fetchData((data: unknown[]) => {
          setCollection(data);

          setOffset(0);
        });
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    if (url) {
      debouncedFetch(() => {
        fetchData((data: unknown[]) => {
          setCollection(
            collection.concat(data)
          );
        });
      });
    }
  }, [offset]);

  return [onScrollBottom, updateSearchQuery, collection];
};

export default useSelect;
