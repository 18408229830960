import React, { FC } from 'react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import SelectionProps from 'types/Select2/SelectionProps';

const ModalDropDown: FC<Pick<SelectionProps, 'setExpanded' | 'parentRef'>> = ({
  setExpanded,
  parentRef
}) => {
  const handleClick = e => {
    if (!parentRef.current.contains(e.target)) setExpanded(false);
  };

  useEffect(() => { 
    document.addEventListener('click', handleClick);

    return function cleanup () {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    createPortal(
      <div className='modal-background' />,
      document.querySelector('#page-content')
    )
  );
};

export default ModalDropDown;
