import React, { FC, useState, ChangeEvent } from 'react';
import Col from 'components/Bootstrap/Col';
import Row from 'components/Bootstrap/Row';

const MissingForm: React.FC = () => {
    const [isMissingInfoChecked, setIsMissingInfoChecked] = useState<boolean>(false);
    const [missingInfoText, setMissingInfoText] = useState<string>('');

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsMissingInfoChecked(event.target.checked);
        if (!event.target.checked) {
            setMissingInfoText(''); // Reset the input value when unchecked
        }
    };

    return (
        <Row>
            <Col sm={5}>
                <label>Missing Info?</label>
            </Col>
            <Col sm={1}>
                <input
                    name='case[missing_info_checkbox]'
                    type='checkbox'
                    className="form-control form-control-sm"
                    onChange={handleCheckboxChange}
                />
            </Col>
            {isMissingInfoChecked && (
                <Col sm={6}>
                    <input
                        name='case[missing_info_text]'
                        placeholder='Enter details of missing information'
                        className="form-control form-control-sm"
                        required={isMissingInfoChecked}
                        value={missingInfoText}
                        onChange={(event) => setMissingInfoText(event.target.value)}
                    />
                </Col>
            )}
        </Row>
    );
};

export default MissingForm;
