import pluralize from 'pluralize';
import railsNestedResource from '../railsNestedResources';

const nestedResourceName =
  (resourceName: string, index: number, attribute: string): string => {
    return (
      railsNestedResource(
        'enrollment',
        pluralize(resourceName),
        index,
        attribute
      )
        .name
    );
  };

export default nestedResourceName;
