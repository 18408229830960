import ErrorComponent from 'components/ErrorComponent';
import useErrors from 'hooks/enrollments/useErrors';
import useInputsCache from 'hooks/enrollments/useInputsCache';
import useNpiSummaries from 'hooks/enrollments/useNpiSummaries';
import { singular } from 'pluralize';
import React, { FC, useEffect, useReducer, useRef, useState, } from 'react';
import fieldsReducer, { initState } from '../../reducers/enrollments/fieldsReducer';
import EnrollmentFormProps from '../../types/enrollments/EnrollmentFormProps';
import ContactsCard from './ContactsCard';
import DoctorsCard from './DoctorsCard';
import PracticesCard from './PracticesCard';

const EnrollmentForm: FC<EnrollmentFormProps> = ({
  enrollment,
  enrollment_errors,
  enrollment_practices_errors,
  enrollment_contacts_errors,
  enrollment_doctors_errors,
  npi_summaries,
  ref_taxonomies,
  method,
  ...initialFields
}) => {
  const [fields, resourceDispatch] = useReducer(
    fieldsReducer,
    { enrollment, ...initialFields },
    initState as undefined
  );

  const [process, setProcess] = useState(false);
  const [deny, setDeny] = useState(false);

  const inputsCache = useInputsCache(fields);

  const [errors] = useErrors(fields, inputsCache, {
    enrollment_practices_errors,
    enrollment_contacts_errors,
    enrollment_doctors_errors,
    enrollment_errors
  });

  const [npiSummaries, getNpiSummary] = useNpiSummaries(npi_summaries);

  const handleProcessClick = (e) => {
    e.preventDefault();

    setProcess(true);
  };
  const handleDenyClick = (e) => {
    e.preventDefault();
    setDeny(true);
  };

  useEffect(() => {
    if (process && submitRef.current) submitRef.current.click();
  }, [process]);

  useEffect(() => {
    if (deny && submitRef.current) submitRef.current.click();
  }, [deny]);
  const submitRef = useRef<HTMLInputElement>();

  return (
    <>
      <input type='hidden' name='enrollment[process]' value={String(process)} />
      <input type='hidden' name='enrollment[deny]' value={String(deny)} /> {/* Added deny hidden input */}
      {enrollment_errors && enrollment_errors.length > 0 && (
        <ErrorComponent p={3} m={3}>
          <p><strong>Please Correct the following errors:</strong></p>
          <ul>
            {enrollment_errors.map((error, index) => (
              <li key={index}>
                {singular(error.replace('enrollment_', ''))}
              </li>
            ))}
          </ul>
        </ErrorComponent>
      )}
      <PracticesCard
        practices={fields.enrollment_practices}
        npiSummaries={npiSummaries}
        getNpiSummary={getNpiSummary}
        resourceDispatch={resourceDispatch}
        errors={errors.enrollment_practices_errors}
      />
      <ContactsCard
        contacts={fields.enrollment_contacts}
        resourceDispatch={resourceDispatch}
        errors={errors.enrollment_contacts_errors}
      />
      <DoctorsCard
        doctors={fields.enrollment_doctors}
        npiSummaries={npiSummaries}
        getNpiSummary={getNpiSummary}
        resourceDispatch={resourceDispatch}
        refTaxonomies={ref_taxonomies}
        errors={errors.enrollment_doctors_errors}
      />
      <div className='text-right m-2'>
        {
          fields.enrollment_practices[0].active && (
            <input
              type='submit'
              className={`btn mr-2 btn-${
                fields.enrollment_practices[0].accept ? 'success' : 'danger'
              }`}
              value={fields.enrollment_practices[0].accept ? 'Accept' : 'Deny'}
              onClick={fields.enrollment_practices[0].accept ? handleProcessClick : handleDenyClick}
            />
          )
        }
        <input
          type='submit'
          className='btn btn-primary'
          value={
            ['update', 'edit'].includes(method) ? (
              'Update Enrollment' 
            ) : (
              'Create Enrollment'
            )}
          ref={submitRef}
        />
      </div>
    </>
  );
};

export default EnrollmentForm;
