import React, { ChangeEvent, FC, useState } from 'react';
import Input from 'components/Input';
import Patient from 'types/Patient';
import Doctor from 'types/Doctor';
import Practice from 'types/Practice';
import Prescription from 'types/faxes_ins_cases/Prescription';
import railsNestedResource from 'utils/railsNestedResources';
import Insurance from 'types/Insurance';
import ErrorComponent from 'components/ErrorComponent';

type CaseFormInputProps = {
  label: string;
  attribute: string;
  resource: Patient | Doctor | Practice | Prescription | Insurance | { pdf_pages: string };
  onChange: (e: ChangeEvent<HTMLInputElement>, attribute: string) => void;
  nestedResourceName?: string;
  type?: string;
  placeholder?: string;
  hidden?: boolean
  value?: string | boolean;
  deeplyNested?: boolean;
  required?: string;
  attr_hash? :any;
  id?: string;
  parent_ref?
};

const CaseFormInput: FC<CaseFormInputProps> =
  ({
    label,
    attribute,
    resource,
    onChange,
    nestedResourceName,
    children,
    placeholder,
    required,
    deeplyNested,
    ...props
  }) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (props.type === 'checkbox') {
        onChange(
          {
            ...e,
            target: {
              ...e.target,
              value: e.target.checked
            }
          } as unknown as ChangeEvent<HTMLInputElement>,
          attribute
        );
      } else {
        onChange(e, attribute);
      }
    };

    const inputName = () => {
      if (deeplyNested) {
        return 'case' + attribute;
      } else if (nestedResourceName) {
        return (
          railsNestedResource('case', nestedResourceName, null, attribute).name
        );
      } else {
        return `case[${attribute}]`;
      }
    };

    const inputId = () => {
      if (deeplyNested) {
        return `case_${props.id}`;
      } else if (props.id) {
        return props.id;
      } else if (nestedResourceName) {
        return (
          railsNestedResource('case', nestedResourceName, null, attribute).id
        );
      } else {
        `case_${attribute}`;
      }
    };

    if (props.type === 'select') {
      return (
        <>
          <CaseFormInput
            label={label}
            attribute={attribute}
            onChange={handleOnChange}
            resource={resource}
            {...props}
            nestedResourceName={nestedResourceName}
            deeplyNested={deeplyNested}
            type='hidden'
            placeholder={placeholder}
            value={props.value}
          />
          {!props.hidden && (
            <>
              <label>{label}</label>
              {children}
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <Input
            value={props.value || String(resource[attribute] || '')}
            label={label}
            onChange={handleOnChange}
            className='form-control form-control-sm'
            id={inputId()}
            placeholder={placeholder}
            required={required}
            name={inputName()}
            {...props}
          />
          {props.attr_hash && props.attr_hash[attribute] && props.attr_hash[attribute]['message'] && (
            <ErrorComponent>
              {props.attr_hash[attribute]['message']}
            </ErrorComponent>
          )}
        </>
      );
    }
  };

export default CaseFormInput;
