import Col from 'components/Bootstrap/Col';
import Row from 'components/Bootstrap/Row';
import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import Patient from 'types/Patient';
import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import CaseFormInput from './CaseFormInput';
import Select2 from 'components/Select2/Select2';
import PatientPhoneNumberTypes from 'types/PatientPhoneNumberTypes';
import PatientPhoneNumber from 'types/PatientPhoneNumber';
import railsNestedResource from 'utils/railsNestedResources';
import Titles from 'types/Titles';
import Genders from 'types/Genders';
import InsuranceForm from 'components/faxes_ins_cases/New/InsuranceForm';
import AddressForm from 'components/faxes_ins_cases/New/AddressForm';
import removeNullFromObject from 'hooks/faxes_ins/removeNullFromObject';
import {isRequiredValidation} from 'utils/validations';
import flatPicker from 'hooks/faxes_ins/flatPicker';
import DynamicAttributesForm from '../DynamicAttributesForm';

type PatientFormProps =
  & ResourceForm
  & {
    patient?: Patient;
    attr_hash?: any;
  }

const DEFAULT_PHONE_NUMBER = {
  number: '',
  phone_type: 'home'
};

const PatientForm: FC<PatientFormProps> =
  ({ patient = {} as Patient, visible, onChange, attr_hash }) => {
    const [phoneNumbers, setPhoneNumbers] = useState<PatientPhoneNumber[]>( []);

    const typeProps = visible ? {} : { type: 'hidden' };

    const [title, setTitle] = useState<keyof typeof Titles>();
    const [dateOfBirth, setDateOfBirth] = useState<string>();
    const [gender, setGender] = useState<keyof typeof Genders>();

    const inputProps = {
      resource: patient,
      onChange,
      attr_hash: attr_hash.globalpatient,
      nestedResourceName: 'patient',
      ...typeProps
    };

    const phoneInputProps = {
      resource: patient,
      onChange,
      ...typeProps
    };

    const handlePhoneNumberChange = (e, attribute, index) => {
      setPhoneNumbers(phoneNumbers.map((phoneNumber, phoneNumberIndex) => {
        if (phoneNumberIndex === index) {
          return ({
            ...phoneNumber,
            [attribute]: e.target.value
          });
        } else {
          return phoneNumber;
        }
      }));
    };

    const handlePhoneNumberTypeClick = (value, index) => {
      handlePhoneNumberChange({ target: { value }}, 'phone_type', index);
    };

    const handleAddPhoneNumber = () => {
      setPhoneNumbers(phoneNumbers.concat([ DEFAULT_PHONE_NUMBER ]));
    };

    const handleRemovePhoneNumber = (index: number) => {
      // if (phoneNumbers[index].id){
      //   handlePhoneNumberChange({ target: { value: true }},'_destroy',index);
      // } else {
      setPhoneNumbers(phoneNumbers.filter((_phoneNumber, phoneNumberIndex) => {
        return phoneNumberIndex !== index;
      }));
      // }
    };
    const handleDobOnChange = (val: string) => {
      if (val){
        setDateOfBirth(val);
      }
    };

    const handleGenderOnChange = (gender: string) => {
      const event = {
        target: { value: gender }
      } as ChangeEvent<HTMLInputElement>;
      onChange(event, 'gender');
    };

    useEffect(() => {
      if (phoneNumbers.length === 0) {
        handleAddPhoneNumber();
      }
    }, [phoneNumbers]);

    useEffect(() => {
      if (visible){
        flatPicker('case_patient_attributes__dob', handleDobOnChange);
      }
    },[visible]);

    useEffect(() => {
      if (patient?.dob){
        setDateOfBirth(patient?.dob);
      }
    },[patient?.dob]);


    useEffect(() => {
      if (patient.phone_numbers){
        removeNullFromObject(patient.phone_numbers);
        setPhoneNumbers(patient.phone_numbers);
      }
    },[patient.phone_numbers]);

    const phoneInputAttribute =
      (attribute: '_destroy' | 'number' | 'phone_type', index: number) => {
        const { name, id } = railsNestedResource(
          'patient_attributes',
          'phone_numbers',
          index,
          attribute
        );

        return {
          name: name.replace(/patient_attributes\[/, '[patient_attributes]['),
          id
        };
      };

    return (
      <>
        <Row>
          <Col sm={3}>
            <CaseFormInput
              type='select'
              hidden={!visible}
              label='Title'
              value={title === undefined ? '' : title}
              attribute='title'
              {...inputProps}
            >
              <Select2
                value={title === undefined ? Titles[patient.title] : title}
                placeholder='Select...'
              >
                {
                  (Object.keys(Titles) as (keyof typeof Titles)[])
                    .map((curr: keyof typeof Titles) => {
                      return (
                        <Select2.Option
                          key={curr}
                          onClick={() => { setTitle(curr); }}
                        >
                          {Titles[curr]}
                        </Select2.Option>
                      );
                    })
                }
              </Select2>
            </CaseFormInput>
          </Col>
          <Col sm={3}>
            <CaseFormInput
              label='First Name'
              attribute='firstname'
              required={isRequiredValidation('globalpatient','firstname',attr_hash)}
              {...inputProps}
            />
          </Col>
          <Col sm={3}>
            <CaseFormInput
              label='Middle Name'
              attribute='middlename'
              {...inputProps}
            />
          </Col>
          <Col sm={3}>
            <CaseFormInput
              label='Last Name'
              attribute='lastname'
              {...inputProps}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <CaseFormInput
              type='select'
              hidden={!visible}
              label='Gender'
              value={gender === undefined ? Genders[patient.gender] : gender}
              attribute='gender'
              {...inputProps}
            >
              <Select2
                value={gender === undefined ? Genders[patient.gender] : gender}
                placeholder='Gender...'
              >
                {
                  (Object.keys(Genders) as (keyof typeof Genders)[])
                    .map((curr: keyof typeof Genders) => {
                      return (
                        <Select2.Option
                          key={curr}
                          onClick={() => { handleGenderOnChange(curr); }}
                        >
                          {Genders[curr]}
                        </Select2.Option>
                      );
                    })
                }
              </Select2>
            </CaseFormInput>
          </Col>
          <Col sm={4}>
            <CaseFormInput label='SSN' attribute='ssn' {...inputProps} />
          </Col>
          <Col sm={4}>
            <CaseFormInput
              label='Date of Birth'
              attribute='dob'
              value={dateOfBirth === undefined ? '' : dateOfBirth}
              required={isRequiredValidation('globalpatient','dob',attr_hash)}
              {...inputProps}
            />
          </Col>
        </Row>
        <AddressForm
          resource={patient}
          onChange={onChange}
          visible={visible}
          resourceName={'patient'}
        />
        {
          phoneNumbers.map((phoneNumber, index) => {
            return (
              <div
                key={phoneNumber + String(index)}
                style={!phoneNumber._destroy ? { } : { display: 'none' }}
              >
                <Row>
                  <Col sm={5}>
                    <CaseFormInput
                      {...phoneInputProps}
                      label='Phone Number'
                      value={phoneNumber.number}
                      onChange={(e) => {
                        handlePhoneNumberChange(e, 'number', index);
                      }}
                      attribute={phoneInputAttribute('number', index).name}
                      id={phoneInputAttribute('number', index).id}
                      deeplyNested
                    />
                  </Col>
                  <Col sm={4}>
                    <CaseFormInput
                      {...inputProps}
                      type='select'
                      hidden={!visible}
                      label='Type'
                      value={phoneNumber.phone_type}
                      attribute={phoneInputAttribute('phone_type', index).name}
                      id={phoneInputAttribute('phone_type', index).id}
                      deeplyNested
                    >
                      <Select2
                        value={PatientPhoneNumberTypes[phoneNumber.phone_type.toLowerCase()]}
                        placeholder='Select a Type...'
                      >
                        {
                          (
                            Object.keys(PatientPhoneNumberTypes) as (
                              keyof typeof PatientPhoneNumberTypes)[]
                          )
                            .map((curr: keyof typeof PatientPhoneNumberTypes) => {
                              return (
                                <Select2.Option
                                  key={curr}
                                  onClick={() => {
                                    handlePhoneNumberTypeClick(curr, index);
                                  }}
                                >
                                  {PatientPhoneNumberTypes[curr]}
                                </Select2.Option>
                              );
                            })
                        }
                      </Select2>
                    </CaseFormInput>
                  </Col>
                  <CaseFormInput
                    {...phoneInputProps}
                    value={String(phoneNumber._destroy) === 'undefined' ? '' : String(phoneNumber._destroy)}
                    type='hidden'
                    label='destroy'
                    attribute={phoneInputAttribute('_destroy', index).name}
                    id={phoneInputAttribute('_destroy', index).id}
                    deeplyNested
                  />
                  <Col md={3}>
                    <div
                      className="text-right"
                      style={visible ? { } : { display: 'none' }}
                    >
                      <label></label>
                      <a
                        className='add_fields btn btn-danger my-2'
                        onClick={() => { handleRemovePhoneNumber(index); }}
                        style={{ display: 'block', color: 'white' }}
                      >
                        Remove
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })
        }
        <Row>
          <Col md={12}>
            <div
              className="text-right"
              style={visible ? {} : { display: 'none' }}
            >
              <a
                className='add_fields btn btn-success my-2'
                onClick={handleAddPhoneNumber}
                style={{ color: 'white' }}
              >
                Add Phone Number
              </a>
            </div>
          </Col>
        </Row>
        <InsuranceForm
          patient={patient}
          onChange={onChange}
          visible={visible}
          attr_hash={attr_hash}
        />
        <DynamicAttributesForm
          resource={patient}
          attributes_of={'patient'}
          attr_hash={attr_hash?.attribute_fields?.global_patient}
          onChange={onChange}
        />
      </>
    );
  };


export default PatientForm;
