import Card from 'components/Bootstrap/Card';
import Col from 'components/Bootstrap/Col';
import Row from 'components/Bootstrap/Row';
import PdfViewer from 'components/PdfViewer';
import Select2 from 'components/Select2/Select2';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import CaseCategories from 'types/CaseCategories';
import Urgencies from 'types/Urgencies';
import Priorities from 'types/Priorities';
import Doctor from 'types/Doctor';
import FaxesIn from 'types/FaxesIn';
import Prescription from 'types/faxes_ins_cases/Prescription';
import Patient from 'types/Patient';
import Practice from 'types/Practice';
import Procedure from 'types/Procedure';
import CaseFormInput from './CaseFormInput';
import DoctorForm from './DoctorForm';
import DropDown from './Dropdown';
import PatientForm from './PatientForm';
import PracticeForm from './PracticeForm';
import PrescriptionForm from './PrescriptionForm';
import useDebouncedFetch from 'hooks/useDebouncedFetch';
import {handleValidations} from '../../../utils/validations';
import Modal  from 'components/Bootstrap/Modal';
import MissingForm from "./MissingForm";
type NewProps = {
  faxes_in: FaxesIn;
  data_hash: any;
};

const DEFAULT_PRESCRIPTION = {
  is_daw: true,
  transferable: true,
  refills_authorized: 0,
};

const New: FC<NewProps> = ({ faxes_in, data_hash }) => {
  const [doctors, setDoctors] = useState<Doctor[]>([]);
  const [practices, setPractices] = useState<Practice[]>([]);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [procedures, setProcedures] = useState<Procedure[]>([]);
  const [pdfPages, setPdfPages] = useState<string>('');
  const [selectAllPdf,setSelectAllPdf] = useState<boolean>(true);
  const [doctorNew,setDoctorNew] = useState<boolean>(true);
  const [practiceNew,setPracticeNew] = useState<boolean>(true);
  const [patientNew,setPatientNew] = useState<boolean>(true);
  const [practice, setPractice] = useState<Practice>();
  const [doctor, setDoctor] = useState<Doctor>();
  const [patient, setPatient] = useState<Patient>();
  const [category, setCategory] = useState<keyof typeof CaseCategories>();
  const [urgency, setUrgency] = useState<keyof typeof Urgencies>();
  const [priority, setPriority] = useState<keyof typeof Priorities>();
  const [prescription, setPrescription] = useState<Prescription>(DEFAULT_PRESCRIPTION as Prescription);
  const [procedure, setProcedure] = useState<Procedure>();
  const [pages, setPages] = useState({});
  const [selectedPages, setSelectedPages] = useState<number[]>([]);
  const debouncedFetch = useDebouncedFetch();
  const [showModal, setShowModal] = useState(false);
  //const openModal = () => { setShowModal(true);};
  const closeModal = () => { setShowModal(false);};
  const customHeader = <h5 className="modal-title">Active Transfer</h5>;
  const customBody = <p>Quick Start process will activate the transfer workflow even if benefits have not been taken.</p>;

  useEffect(() => {
    if (urgency ==='expedited_quick_start'){
      setPriority('urgent');
      setShowModal(true);
    }
  }, [urgency]);

  const personName = (person: Doctor | Patient) => {
    if (!person) return;

    if (!person?.id) return('');

    return (
      person.firstname +
      ' ' +
      (person.middlename ? person.middlename + ' ' : '') +
      person.lastname
    );
  };

  const handleNumChange = (attribute: string) => {
    const pageHash = {};
    const tmpPageArr = Array(Number(attribute)).fill((_, i) => i).map((_, i) => i);
    if (attribute) {

      tmpPageArr.map((a) => {
        pageHash[Number(a)] = true;
      });
    }
    setPages(pageHash);
    setSelectedPages(Array(Number(attribute)).fill((_, i) => i).map((_, i) => i + 1));
  };

  const procedureName = (procedure: Procedure) => {
    if (!procedure) return;

    return procedure.code + ' - ' + procedure.description;
  };

  const fetchResource = async (url: string,prev_resource: Doctor|Practice|Patient): Promise<Doctor|Practice|Patient> => {
    const resp = await fetch(url);
    const body = resp.ok ? await resp.json() : null;
    const resource = await body ? body : prev_resource;
    return resource;
  };

  const handleDoctorOnChange =
    (e: ChangeEvent<HTMLInputElement>, attribute: string) => {
      const requiredValue = e.target.value;
      if (e.target.name == 'case[doctor_attributes][npi]'
        && requiredValue.length == 10) {
        searchDoctor(requiredValue,attribute);
      } else {
        setDoctor({
          ...doctor,
          [attribute]: requiredValue
        });
      }
      handleValidations(data_hash,'globaldoctor',attribute,requiredValue);
    };

  const searchDoctor = (requiredValue: string,attribute: string) => {
    debouncedFetch(() => {
      fetchResource(
        `/global_doctors/search.json?npi=${requiredValue}`,doctor)
        .then(
          (curr: Doctor) => {
            if(curr && curr !== doctor) {
              setDoctor(curr);
              checkDoctorExist(curr);
            } else {
              setDoctorNew(true);
              setDoctor({
                ...doctor,
                [attribute]: requiredValue
              });
            }
          });
    });
  };

  const checkDoctorExist = (curr: Doctor) => {
    const doctorExist = doctors.find(doc => doc.npi == curr.npi);
    if (!doctorExist) {
      setDoctors(doctors.concat(curr));
    }
  };

  const handlePracticeOnChange =
    (e: ChangeEvent<HTMLInputElement>, attribute: string) => {
      const requiredValue = e.target.value;
      if (e.target.name == 'case[practice_attributes][npi]'
        && requiredValue.length == 10) {
        searchPractice(requiredValue,attribute);
      } else {
        setPractice({
          ...practice,
          [attribute]: e.target.value
        });
      }
      handleValidations(data_hash,'practice',attribute,requiredValue);
    };

  const searchPractice = (requiredValue: string,attribute: string) => {
    debouncedFetch(() => {
      fetchResource(
        `/practices/search.json?npi=${requiredValue}`,practice)
        .then(
          (curr: Practice) => {
            if(curr && curr !== practice) {
              setPractice(curr);
              checkPracticeExist(curr);
            } else {
              setPracticeNew(true);
              setPractice({
                ...practice,
                [attribute]: requiredValue
              });
            }
          });
    });
  };

  const checkPracticeExist = (curr: Practice) => {
    const practiceExist = practices.find(pra => pra.npi == curr.npi);
    if (!practiceExist) {
      setPractices(practices.concat(curr));
    }
  };

  const handlePatientOnChange =
    (e: ChangeEvent<HTMLInputElement>, attribute: string) => {
      const currentElementValue = e.target.value;
      // const insurance_attr = e.target.name.split('case')[1];
      setPatient({
        ...patient,
        [attribute]: currentElementValue
      });
      handleValidations(data_hash,'globalpatient',attribute,currentElementValue);
      // handleValidations(data_hash,'insurance',attribute,currentElementValue,insurance_attr);
    };

  const handlePdfSelectChange =
    (e: ChangeEvent<HTMLInputElement>, attribute: string) => {
      const toFindInSelected = Number(attribute) + 1;
      if(selectedPages.includes(toFindInSelected)){
        setSelectedPages(selectedPages.filter((key, index) => {
          return key !== toFindInSelected;
        }));
        setPages(prev => ({...prev, [Number(attribute)]: false}));
      }else{
        setSelectedPages(selectedPages.concat(toFindInSelected));
        setPages(prev => ({...prev, [Number(attribute)]: true}));
      }
    };

  useEffect(() => {
    if (Object.values(pages).includes(false)){
      setSelectAllPdf(false);
    }else{
      setSelectAllPdf(true);
    }
  },[pages]);

  useEffect(() => {
    const reasonField = document.getElementById('reasonField');
    const case_reason = document.getElementById('case_reason') as HTMLInputElement;
    if (category === 'denial') {
      if (reasonField) {
        reasonField.style.display = 'block';
        case_reason.disabled = false;
      }
    } else {
      if (reasonField) {
        reasonField.style.display = 'none';
        case_reason.disabled = true;
      }
    }
  }, [category]);

  const patientQuery = () =>
    `firstname=${patient.firstname}&lastname=${patient.lastname}&middlename=${patient.middlename ? patient.middlename : ''}&gender=${patient.gender}&dob=${patient.dob}&practice_id=${practice?.id}`;

  const searchPatient = () => {
    debouncedFetch(() => {
      fetchResource(
        `/global_patients/search.json?${patientQuery()}`,patient)
        .then(
          (curr: Patient) => {
            if(curr && curr !== patient) {
              setPatient(curr);
              setPatientNew(false);
              checkPatientExist(curr);
            } else {
              setPatientNew(true);
            }
          });
    });
  };

  const checkPatientExist = (curr: Patient) => {
    const patientExist = patients.find(pra => pra.id == curr.id);
    if (!patientExist) {
      setPatients(patients.concat(curr));
    }
  };

  const handlePrescriptionOnChange =
    (e: ChangeEvent<HTMLInputElement>, attribute: string) => {
      setPrescription({
        ...prescription,
        [attribute]: e.target.value
      });
    };

  const [bodyHeightStyle, setBodyHeightStyle] = useState({} as { height: number });

  const containerRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();

  const headerHeight = () => (containerRef.current?.offsetHeight || 0) -
    (headerRef.current?.offsetHeight || 0);

  const handleResize = () => {
    setBodyHeightStyle({ height: headerHeight() });
  };

  window.onresize = handleResize;

  useEffect(() => {
    setBodyHeightStyle({ height: headerHeight() });
  }, [containerRef, headerRef]);

  useEffect(() => {
    if (
      patient?.firstname &&
      patient?.lastname &&
      patient?.dob &&
      patient?.gender &&
      practice?.id){
      searchPatient();
    }
  }, [patient?.firstname, patient?.lastname,patient?.dob,patient?.gender,practice?.id]);

  return (
    <div ref={containerRef} style={{ height: '100%' }}>
      <Row px={2} ref={headerRef}>
        <h1>New Case</h1>
      </Row>
      <Row
        style={{
          overflow: 'auto',
          ...bodyHeightStyle,
        }}
      >
        <Col md={6}>
          <PdfViewer
            url={`/faxes_ins/${faxes_in.id}/sourcefile`}
            height={bodyHeightStyle.height}
            onChange={handleNumChange}
          />
        </Col>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <Card mb={2} mx={2}>
                <Card.Header clearfix>Case</Card.Header>
                <Card.Body p={2}>
                  <Row>
                    <Col sm={6}>
                      <input
                        type='hidden'
                        name='case[category]'
                        value={category}
                      />
                      <label>Category</label>
                      <Select2
                        value={CaseCategories[category]}
                        placeholder='Select a Category...'
                        onChange={(curr) => setCategory(curr)}
                      >
                        {
                          (Object.keys(CaseCategories) as (keyof typeof CaseCategories)[])
                            .map((curr: keyof typeof CaseCategories) => {
                              return (
                                <Select2.Option
                                  key={curr}
                                  resource={curr}
                                >
                                  {CaseCategories[curr]}
                                </Select2.Option>
                              );
                            })
                        }
                      </Select2>

                      <br/>
                    </Col>
                    <Col sm={6}>
                      <label>Pages</label>
                      <input
                        name='case[pdf_pages]'
                        placeholder='pages (e.g. 1,2-4,10)'
                        className="form-control form-control-sm"
                      />
                    </Col>
                  </Row>

                  <MissingForm></MissingForm>

                  <Row>
                    <Col sm={3}>
                      <label>SureScripts EPA Priority (High is checked)</label>
                    </Col>
                    <Col sm={2}>
                      <input
                          name='case[priority_check]'
                          type='checkbox'
                          className="form-control form-control-sm"
                      />
                    </Col>
                    <Col sm={7}>
                      <div id='reasonField'>
                        <label>Reason</label>
                        <input
                            name='case[reason]'
                            placeholder='Give status reason'
                            className="form-control"
                            id={'case_reason'}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <input
                        type='hidden'
                        name='case[priority]'
                        value={priority}
                      />
                      <label>Priority</label>
                      <Select2
                        value={Priorities[priority]}
                        placeholder='Select a Priority...'
                        onChange={(curr) => setPriority(curr)}
                      >
                        {
                          (Object.keys(Priorities) as (keyof typeof Priorities)[])
                            .map((curr: keyof typeof Priorities) => {
                              return (
                                <Select2.Option
                                  key={curr}
                                  resource={curr}
                                >
                                  {Priorities[curr]}
                                </Select2.Option>
                              );
                            })
                        }
                      </Select2>
                    </Col>

                    <Col sm={6}>
                      <input
                        type='hidden'
                        name='case[urgency]'
                        value={urgency}
                      />
                      <label>Urgency</label>
                      <Select2
                        value={Urgencies[urgency]}
                        placeholder='Select a Urgency...'
                        onChange={(curr) => setUrgency(curr)}
                      >
                        {
                          (Object.keys(Urgencies) as (keyof typeof Urgencies)[])
                            .map((curr: keyof typeof Urgencies) => {
                              return (
                                <Select2.Option
                                  key={curr}
                                  resource={curr}
                                >
                                  {Urgencies[curr]}
                                </Select2.Option>
                              );
                            })
                        }
                      </Select2>
                    </Col>
                  </Row>
            </Card.Body>
              </Card>
            </Col>
            <DropDown
              title='Global Doctor'
              value={personName(doctor)}
              placeholder={'Select Global Doctor...'}
              collection={doctors}
              getDisplayName={(curr: Doctor) => curr.dropname}
              onChange={(curr: Doctor) => { setDoctor(curr); }}
              getKey={(curr: Doctor) => curr.id}
              name='case[doctor_id]'
              formValue={doctor?.id}
              includeNew={doctorNew}
              defaultResource={{} as Doctor}
              url='/global_doctors'
              setCollection={setDoctors as (doctors: Doctor[]) => void}
            >
              <DoctorForm
                doctor={doctor}
                onChange={handleDoctorOnChange}
                visible={Boolean(doctor)}
                attr_hash={data_hash.globaldoctor}
              />
            </DropDown>
            <DropDown
              title='Practice'
              value={practice?.name}
              placeholder={'Select Practice...'}
              collection={practices}
              getDisplayName={(curr: Practice) =>  curr.dropname}
              onChange={(curr: Practice) => { setPractice(curr); }}
              getKey={(curr: Practice) => curr.id}
              name='case[practice_id]'
              formValue={practice?.id}
              includeNew={practiceNew}
              defaultResource={{} as Practice}
              url='/practices'
              setCollection={setPractices as (practices: Practice[]) => void}
              optionalDependancyName={ 'global_doctor_id' }
              optionalDependancy={ doctor?.id }
            >
              <PracticeForm
                practice={practice}
                onChange={handlePracticeOnChange}
                visible={Boolean(practice)}
                attr_hash={data_hash?.practice}
                practice_attributes={data_hash?.attribute_fields?.practice}
              />
            </DropDown>
            <DropDown
              title='Global Patient'
              value={personName(patient)}
              placeholder={'Select Global Patient...'}
              collection={patients}
              getDisplayName={(curr: Patient) => personName(curr)}
              onChange={(curr: Patient) => { setPatient(curr); }}
              getKey={(curr: Patient) => curr.id}
              name='case[patient_id]'
              formValue={patient?.id}
              defaultResource={{} as Patient}
              includeNew = {patientNew}
              url={ '/global_patients' }
              setCollection={setPatients as (patients: Patient[]) => void}
              optionalDependancyName={ 'practice_id' }
              optionalDependancy={ practice?.id }
            >
              <PatientForm
                patient={patient}
                onChange={handlePatientOnChange}
                visible={Boolean(patient)}
                attr_hash={data_hash}
              />
            </DropDown>

            <DropDown
                title='Procedure'
                value={procedureName(procedure)}
                placeholder={'Select Procedure'}
                collection={procedures}
                getDisplayName={(curr: Procedure) => procedureName(curr)}
                onChange={(curr: Procedure) => { setProcedure(curr); }}
                getKey={(curr: Procedure) => curr.id}
                name='case[procedure_attributes][cpt_code]'
                formValue={procedure?.code}
                defaultResource={{} as Procedure}
                url='/ref_cpt_codes'
                setCollection={setProcedures as (procedures: Procedure[]) => void}
            />

            <PrescriptionForm
              prescription={prescription}
              attr_hash={data_hash?.attribute_fields?.prescription}
              onChange={handlePrescriptionOnChange}
            />

            <div className='text-right m-2'>
              <input
                disabled = {data_hash.errors && data_hash.errors.length > 0}
                type='submit'
                className='btn btn-primary'
                value='Create Case'
              />
            </div>
          </Row>
        </Col>
      </Row>
      <div>
        <Modal
            showModal={showModal}
            closeModal={closeModal}
            modalHeader={customHeader}
            modalBody={customBody}
        />
      </div>
    </div>
  );
};

export default New;
