export const check_presence = (input: string,options: any) => {
  if(input && input.length) {
    return {isvalid: true,message: undefined};
  }else {
    return {isvalid: false,message: 'Can\'t be blank'};
  }
};
export  const check_length = (input: string,options: any) => {
  let from_length,to_length;
  if(options.is){
    from_length =  options.is;
    to_length = options.is;
  }else if (options.maximum) {
    from_length =  '1';
    to_length = options.maximum;
  }
  if(input.length <= to_length && input.length >= from_length){
    return {isvalid: true,message: undefined};
  }else {
    return {isvalid: false,message: `input length should be between ${from_length} and ${to_length}`};
  }
};
export const check_uniqueness = (input: string,options: any) => {
  return {isvalid: true,message: undefined};
};

const getValidations = (type: string,attr:string,data_hash: any) => {
  return data_hash[type].validations.filter((validation) => {return validation[attr];}).map(validation => {return validation[attr];});
};


export const isRequiredValidation = (type: string,attr: string,data_hash: any) => {
  const validations = getValidations(type,attr,data_hash);
  return validations.filter(v => v[0] == 'presence').length;
};

export const handleValidations = (data_hash: any,type: string,attribute: string,requiredValue: string,error_attribute?: string) => {
  const validations = getValidations(type,attribute,data_hash);
  const custom_attribute = error_attribute ? error_attribute : attribute;
  if(validations){
    const failed_validations = validations.map((vld) => {
      return eval(`check_${vld[0]}("${requiredValue}",${JSON.stringify(vld[1])})`);
    }).filter((response) => {return !response.isvalid; });
    if(failed_validations.length == 0){
      const error_type_index = data_hash?.errors?.indexOf(`${type}_${attribute}`);
      if(error_type_index >= 0)
        data_hash.errors.splice(error_type_index,1);
      data_hash[type][custom_attribute] = {message: undefined};
    } else {
      const error_type_index = data_hash.errors.indexOf(`${type}_${attribute}`);
      if(error_type_index < 0){
        data_hash.errors.push(`${type}_${attribute}`);
      }
      data_hash[type][custom_attribute] = {message: failed_validations.map((res) => res.message).join(',')};
    }
  }
};
