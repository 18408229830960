import { singular } from 'pluralize';
import React, { FC, memo, useCallback } from 'react';
import Card from '../Bootstrap/Card';
import Col from '../Bootstrap/Col';
import Row from '../Bootstrap/Row';
import Input from '../Input';
import RemoveButton from './RemoveButton';
import EnrollmentCardProps from '../../types/enrollments/EnrollmentCardProps';
import enrollmentCardTestId from '../../utils/enrollments/enrollmentCardTestId';
import nestedResourceId from 'utils/enrollments/nestedResourceId';
import nestedResourceName from 'utils/enrollments/nestedResourceName';
import NpiSummaryType from '../../types/NpiSummary';
import NpiSummary from './NpiSummary/NpiSummary';

const EnrollmentCardBody = ({
  index,
  resourceName,
  npiSummary,
  AdditionalSummaries,
  Children,
  resource,
  resourceDispatch,
  npiSummaries,
  getNpiSummary,
  refTaxonomies,
  errors,
  allowRemove
}) => {
  const removeResource = useCallback((index: number) => {
    resourceDispatch({
      type: 'remove',
      resourceName,
      index
    });
  }, []);

  return (
    <div
      key={index}
      data-testid={
        enrollmentCardTestId(
          'resource-container',
          resourceName,
          index
        )
      }
    >
      {resource._destroy && (
        <Input
          type='hidden'
          value={String(true)}
          name={nestedResourceName(resourceName, index, '_destroy')}
          id={nestedResourceId(resourceName, index, '_destroy')}
        />
      )}
      {
        resource.id && (
          <Input
            type='hidden'
            value={String(resource.id)}
            name={nestedResourceName(resourceName, index, 'id')}
            id={nestedResourceId(resourceName, index, 'id')}
          />
        )
      }
      {
        !resource._destroy && (
          <Card.Body p={2}>
            <Card p={2} mb={2} nested-fields>
              <Row>
                <Col md={4} profile-id='npisummary'>
                  {npiSummary && (
                    <NpiSummary
                      data-testid={
                        enrollmentCardTestId(
                          `npi-summary-${resource.npi}`,
                          resourceName
                        )
                      }
                      npi={resource.npi}
                      npiSummary={npiSummaries[resource.npi] || {} as NpiSummaryType}
                      useNpiValues={(npiValues: NpiSummaryType) => {
                        resourceDispatch({
                          resourceName,
                          type: 'setWithNpiValues',
                          npiValues,
                          index
                        });
                      }}
                      setNpiSummary={() => getNpiSummary(resource)}
                      refTaxonomies={refTaxonomies}
                    />
                  )}
                  {AdditionalSummaries && (
                    <AdditionalSummaries
                      index={index}
                      resource={resource}
                      resourceDispatch={resourceDispatch}
                    />
                  )}
                </Col>
                <Col md={8} mx={0}>
                  {
                    Children && (
                      <Children
                        index={index}
                        resource={resource}
                        resourceDispatch={resourceDispatch}
                        refTaxonomies={refTaxonomies}
                        errors={errors}
                      />
                    )
                  }
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  { allowRemove && (
                    <div className='text-right'>
                      <RemoveButton
                        removeResource={() => {
                          removeResource(index);
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Card>
          </Card.Body>
        )
      }
    </div>
  );
};

const MemoizedEnrollmentCardBody = memo(EnrollmentCardBody);

const EnrollmentCard: FC<EnrollmentCardProps> = ({
  title,
  npiSummary,
  children: Children,
  AdditionalSummaries,
  resources,
  resourceName,
  resourceDispatch,
  npiSummaries,
  getNpiSummary,
  refTaxonomies,
  errors,
  addResourceButton,
  allowRemove = true
}) => {
  const addResource = useCallback(() => {
    resourceDispatch({
      type: 'add',
      resourceName,
    });
  }, []);

  return (
    <Card>
      <Card.Header clearfix>{title}:</Card.Header>
      <div style={{backgroundColor: 'white'}}>
        {
          resources.map((resource, index) => {
            return (
              <MemoizedEnrollmentCardBody
                key={index}
                index={index}
                Children={Children}
                AdditionalSummaries={AdditionalSummaries}
                npiSummary={npiSummary}
                resource={resource}
                resourceName={resourceName}
                resourceDispatch={resourceDispatch}
                npiSummaries={npiSummaries}
                getNpiSummary={getNpiSummary}
                refTaxonomies={refTaxonomies}
                errors={errors[index]}
                allowRemove={allowRemove}
              />
            );
          })
        }
      </div>
      { addResourceButton && (
        <Row>
          <Col md={12}>
            <div className="text-right">
              <a
                data-testid={enrollmentCardTestId('add-button', resourceName)}
                className='add_fields btn btn-success my-2'
                onClick={addResource}
                style={{ color: 'white' }}
              >
                Add {singular(title)}
              </a>
            </div>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default EnrollmentCard;
