import React, { Children, ReactNode } from 'react';
import Table from '../Bootstrap/Table';
import Card from '../Bootstrap/Card';
import Attribute from '../../types/enrollments/Attribute';
import ResourcesSummaryType from '../../types/enrollments/ResourcesSummaryType';
import ResourcesSummaryBodyType from 'types/enrollments/ResourcesSummaryBodyType';

const ResourcesSummary: ResourcesSummaryType = ({ children }) => (
  <Card mb={2} mx={2} style={{ maxHeight: '300px' }}>
    {Children.toArray(children)[0]}
    <Card.Body p={0} style={{ maxHeight: '300px' }} overflow-auto data-turbolinks='false'>
      <Table table-sm mb={0}>
        {Children.toArray(children).slice(1)}
      </Table>
    </Card.Body>
  </Card>
);

const Title = ({ children }) => (
  <Card.Header clearfix>{children}</Card.Header>
);

const Header =
  ({ headers, children }: { headers: string[], children?: ReactNode }) => (
    <Table.Head thead-dark>
      <tr>
        {headers.map((header: string, index: number) => (
          <th scope="col" key={index}>{header}</th>
        ))}
        {children}
      </tr>
    </Table.Head>
  );

const Body: ResourcesSummaryBodyType = ({
  resources = [],
  attributes,
  resourcesPath,
  children
}) => (
  <Table.Body>
    {
      resources?.map((resource, index) => (
        <tr key={index}>
          {
            attributes.map((attribute: Attribute, attributeIndex: number) => (
              <td key={attributeIndex}>
                {
                  attributeIndex === 0 ? (
                    <a
                      href={`${resourcesPath}/${resource[attribute]}/edit`}
                      className='btn btn-primary btn-sm'
                      style={{ color: 'white' }}
                    >
                      {resource[attribute]}
                    </a>
                  ) : (
                    resource[attribute]
                  )
                }
              </td>
            ))
          }
          {children && children(resource, index)}
        </tr>
      ))
    }
  </Table.Body>
);

ResourcesSummary.Title = Title;
ResourcesSummary.Header = Header;
ResourcesSummary.Body = Body;

export default ResourcesSummary;
