import Card from 'components/Bootstrap/Card';
import Col from 'components/Bootstrap/Col';
import Select2 from 'components/Select2/Select2';
import useDebouncedFetch from 'hooks/useDebouncedFetch';
import React, { Children, cloneElement, FC, ReactElement, useEffect } from 'react';
import { ReactText, useState } from 'react';
import CaseCategories from 'types/CaseCategories';
import Doctor from 'types/Doctor';
import Patient from 'types/Patient';
import Practice from 'types/Practice';
import Procedure from 'types/Procedure';
import DiagnosisCode from "types/DiagnosisCode";

type DropDrownProps = {
  value: string;
  placeholder: string;
  collection: (
    Practice |
    Doctor |
    Patient |
    (keyof typeof CaseCategories) |
    Procedure |
    DiagnosisCode
  )[];
  getDisplayName: (element: DropDownResource) => string;
  onChange: (element: DropDownResource | 'New') => void;
  getKey: (element: DropDownResource) => ReactText;
  name: string;
  formValue: string | number;
  onNewClick?: () => void;
  includeNew?: boolean,
  title: string;
  defaultResource?: Practice | Doctor | Patient | Procedure | DiagnosisCode;
  url?: string;
  setCollection?:
    (collection: (Practice | Doctor | Patient | Procedure | DiagnosisCode)[]) => void;
  optionalDependancy?: number;
  optionalDependancyName?: string;
};

type DropDownResource = 
  | Practice
  | Doctor
  | Patient
  | keyof typeof CaseCategories
  | Procedure
  | DiagnosisCode;

const DropDown: FC<DropDrownProps> = ({
  value,
  placeholder,
  collection,
  getDisplayName,
  onChange,
  getKey,
  name,
  formValue,
  includeNew = false,
  title,
  children,
  defaultResource,
  url,
  setCollection,
  optionalDependancyName = '',
  optionalDependancy= ''
}) => {
  const [newSelected, setNewSelected] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);

  const fetchUrl = () =>
    `${url}.json?limit=${limit}&offset=${offset}&search=${searchQuery}&${optionalDependancyName}=${optionalDependancy}`;

  const debouncedFetch = useDebouncedFetch();

  const onScrollBottom = () => {
    setOffset(offset + 50);
  };

  const updateSearchQuery = (query: string) => {
    setSearchQuery(query);
  };

  const handleOnChange = (resource,index?) => {
    setNewSelected(includeNew && resource && index == 0);
    onChange(resource);
  }

  const fetchData = async (cb) => {
    const resp = await fetch(fetchUrl());
    const data = resp.ok ? await resp.json() : [];

    cb(data);
  };

  useEffect(() => {
    if (url) {
      debouncedFetch(() => {
        fetchData((data: (Patient | Doctor | Practice | DiagnosisCode)[]) => {
          setCollection(data);

          setOffset(0);
        });
      });
    }
  }, [searchQuery,optionalDependancy]);

  useEffect(() => {
    if (url) {
      debouncedFetch(() => {
        fetchData((data: (Patient | Doctor | Practice| DiagnosisCode)[]) => {
          setCollection(
            (collection as (Patient | Doctor | Practice | DiagnosisCode)[]).concat(data)
          );
        });
      });
    }
  }, [offset]);

  return (
    <Col md={12}>
      <Card mb={2} mx={2}>
        <Card.Header clearfix>{title}</Card.Header>
        <Card.Body p={2}>
          <Col md={12}>
            <input type='hidden' name={name} value={formValue} />
            <Select2
              value={newSelected && includeNew ? 'New' : value}
              search
              placeholder={placeholder}
              onScrollBottom={onScrollBottom}
              updateSearchQuery={updateSearchQuery}
              onChange={handleOnChange}
            >
              {includeNew && (
                <Select2.Option key={'New'} resource={defaultResource}>
                  New
                </Select2.Option>
              )}
              {collection?.map((curr: DropDownResource,index) => {
                return (
                  <Select2.Option
                    key={getKey(curr)}
                    resource={curr}
                  >
                    {getDisplayName(curr)}
                  </Select2.Option>
                );
              })}
              {!collection?.length && (
                <div style={{ padding: '0.375rem 1.5rem 0.375rem 0.75rem' }}>
                  No Results...
                </div>
              )}
            </Select2>
          </Col>
          {children}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default DropDown;
