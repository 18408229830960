import usePreviousState from '../../hooks/usePreviousState';
import { useEffect, useState } from 'react';
import EnrollmentResource from '../../types/enrollments/EnrollmentResource';
import InputCache from '../../types/enrollments/InputCache';
import InputCacheResource from '../../types/enrollments/InputCacheResource';
import FieldsState from '../../types/enrollments/FieldState';

const resourcesToInputResources = (resources): InputCacheResource[] => {
  return (
    resources.map((resource: EnrollmentResource): InputCacheResource => {
      const valid_flag = validationFlag(resource);
      return Object.entries(resource).reduce((obj, [key, value]) => {
        obj[key] = valid_flag;
        return obj;
      }, {});
    })
  );
};

export const newInputsCache = (fields: FieldsState): InputCache => ({
  enrollment_practices: resourcesToInputResources(fields.enrollment_practices),
  enrollment_contacts: resourcesToInputResources(fields.enrollment_contacts),
  enrollment_doctors: resourcesToInputResources(fields.enrollment_doctors)
});

const useInputsCache = (fields: FieldsState): InputCache => {
  const [inputsCache, setInputsCache] = useState(newInputsCache(fields));
  const previousCache = usePreviousState(inputsCache);

  useEffect(() => {
    if (previousCache && fields) {
      setInputsCache(newInputsCache(fields));
    }
  }, [fields]);

  return inputsCache;
};

function validationFlag(resource: Record<string, any>): boolean {
  const values = Object.values(resource);
  return values.some(value => !(value === null || value === 'Eastern Time (US & Canada)' || value === true || value === false));
}


export default useInputsCache;
