import React, { FC, Fragment, memo, useEffect } from 'react';
import Card from '../../Bootstrap/Card';
import Row from '../../Bootstrap/Row';
import Col from '../../Bootstrap/Col';
import NpiSummaryType from 'types/NpiSummary';
import RefTaxonomy from 'types/enrollments/RefTaxonomy';

const NPI_LENGTH = 10;

const FillButton = ({ fillValues, values }) => (
  <a
    className="btn btn-sm btn-info float-right"
    onClick={() => fillValues(values)}
    style={{ color: 'white' }}
  >
    {'>>'}
  </a>
);

const Value = (
  { attribute, value, defaultValue }:
  { attribute: string, value: string | number, defaultValue?: string }) => {
  const displayDefault = defaultValue?.length > 0 ? defaultValue : 'None';
  const displayValue = value ? value : displayDefault;

  return (
    <Fragment>
      <small><b>{attribute}</b> {displayValue}</small><br />
    </Fragment>
  );
};

type NpiSummaryProps = {
  npi: number;
  useNpiValues: (npiValues: NpiSummaryType) => void;
  npiSummary: NpiSummaryType;
  setNpiSummary: () => void;
  refTaxonomies: RefTaxonomy[];
};

const NpiSummary: FC<NpiSummaryProps> = ({
  npi,
  useNpiValues,
  npiSummary,
  setNpiSummary,
  refTaxonomies,
  ...props
}) => {
  useEffect(() => {
    if (npi && String(npi).length === NPI_LENGTH) setNpiSummary();
  }, [npi]);
  
  const {
    practice_name,
    firstname,
    middlename,
    lastname,
    taxonomy,
    type,
    sln,
    phone_number,
    fax_number,
    street,
    suite,
    city,
    state,
    zip,
  } = (npiSummary || {});

  const providerName = () => {
    return (
      [firstname, middlename, lastname].reduce((acc, curr) => {
        if (curr) {
          if (acc) {
            acc += ` ${curr}`;
          } else {
            acc = curr;
          }
        }

        return acc;
      }, null)
    );
  };

  const npiValues: NpiSummaryType = ({
    practice_name,
    taxonomy,
    sln,
    phone_number,
    fax_number,
    street,
    suite,
    city,
    state,
    zip,
    npi: npiSummary.npi,
    firstname,
    middlename,
    lastname,
    ref_taxonomy_id: (
      refTaxonomies && refTaxonomies.find(({ code }) => code === taxonomy)?.id
    ),
  });

  return (
    <Card mb={2} mx={2} data-testid={props['data-testid']}>
      <Card.Header clearfix>
        NPI Summary
        <FillButton fillValues={useNpiValues} values={npiValues} />
      </Card.Header>
      <Card.Body p={2} data-turbolinks='false'>
        <Row>
          <Col>
            <Value attribute='Practice Name' value={practice_name} defaultValue='' />
            <Value attribute='Provider Name' value={providerName()} />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Value attribute='Npi' value={npiSummary?.npi} defaultValue='' />
            <Value attribute='Taxonomy' value={taxonomy} />
          </Col>
          <Col sm={6}>
            <Value attribute='Type' value={type} />
            <Value attribute='SLN' value={sln} />
          </Col>
        </Row>
        <hr className="my-1" />
        <Row>
          <Col sm={6}>
            <Value attribute='Phone' value={phone_number} />
          </Col>
          <Col sm={6}>
            <Value attribute='Fax' value={fax_number} />
          </Col>
        </Row>
        <hr className="my-1" />
        <Row>
          <Col sm={8}>
            <Value attribute='Street' value={street} />
          </Col>
          <Col sm={4}>
            <Value attribute='Suite' value={suite} />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Value attribute='City' value={city} />
          </Col>
          <Col sm={4}>
            <Value attribute='State' value={state} />
          </Col>
          <Col sm={4}>
            <Value attribute='Zip' value={zip} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const propsAreEqual = (prevProps, props) => {
  return (
    prevProps.npi === props.npi &&
      Object.entries(props.npiSummary).every(([key, value]) => (
        value === prevProps.npiSummary[key]) 
      )
  );
};

export default memo(NpiSummary, propsAreEqual);
