import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import Doctor from 'types/Doctor';
import Practice from 'types/Practice';
import React, {FC, useEffect, useState} from 'react';
import Col from 'components/Bootstrap/Col';
import CaseFormInput from 'components/faxes_ins_cases/New/CaseFormInput';
import Row from 'components/Bootstrap/Row';
import ResourceNumber from 'types/ResourceNumber';
import railsNestedResource from 'utils/railsNestedResources';

type NumberFormProps =
  & ResourceForm
  & { resourceName?: string }
  & {
  disabled?: boolean;
  resource?: Doctor | Practice;
};

const NumberForm: FC<NumberFormProps> =
  ({resource,onChange,visible,resourceName,disabled}) => {
    const typeProps = visible ? {} : { type: 'hidden' };
    const readonly = disabled ? { readonly: 'readonly' } : {};

    const [phoneNumber, setPhoneNumber] = useState<ResourceNumber>({number: ''});
    const [faxNumber, setFaxNumber] = useState<ResourceNumber>({number: ''});


    const numberInputProps = {
      resource: resource,
      onChange,
      ...readonly,
      ...typeProps
    };

    const numberInputAttribute =
      (resource: string) => {
        const { name, id } = railsNestedResource(
          'doctor_attributes',
          resource,
          null,
          'number'
        );
        return {
          name: name.replace(/(practice|doctor)_attributes\[/, `[${resourceName}_attributes][`),
          id
        };
      };

    const handlePhoneNumberChange = (e) => {
      setPhoneNumber({
        ...phoneNumber,
        ['number']: e.target.value
      });
    };

    const handleFaxNumberChange = (e) => {
      setFaxNumber({
        ...faxNumber,
        ['number']: e.target.value
      });
    };

    useEffect(() => {
      if (resource.phone_number){
        setPhoneNumber(resource.phone_number);
      }else{
        setPhoneNumber({number: ''});
      }
    },[resource.phone_number]);

    useEffect(() => {
      if (resource.fax_number){
        setFaxNumber(resource.fax_number);
      }else{
        setFaxNumber({number: ''});
      }
    },[resource.fax_number]);

    return(
      <Row>
        <Col sm={3}>
          <CaseFormInput
            {...numberInputProps}
            label='Phone Number'
            attribute={numberInputAttribute('phone_number').name}
            id={numberInputAttribute('phone_number').id}
            value = {phoneNumber.number}
            onChange={(e) => {
              handlePhoneNumberChange(e);
            }}
            deeplyNested
          />
        </Col>
        <Col sm={3}>
          <CaseFormInput
            {...numberInputProps}
            label='Fax Number'
            attribute={numberInputAttribute('fax_number').name}
            id={numberInputAttribute('fax_number').id}
            value = {faxNumber.number}
            onChange={(e) => {
              handleFaxNumberChange(e);
            }}
            deeplyNested
          />
        </Col>
      </Row>
    );
};

export default NumberForm;