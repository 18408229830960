import React, { ChangeEvent, FC, memo } from 'react';

const Component = ({ value, ...componentProps }) => {
  switch (componentProps.type) {
    case 'checkbox':
      return (
        <input type="checkbox" checked={Boolean(value)} {...componentProps} />
      );
    case 'select':
      return (
        <select value={String(value)} {...componentProps}>
          <option value={String(value)} />
        </select>
      );
    case 'text_area':
      return (
        <input type="textarea"
         value={String(value)}
          placeholder={componentProps.placeholder}
          ref={componentProps.parent_ref}
          autoComplete="off"
          {...componentProps}
        />
      );

    default:
      return (
        <input
          value={String(value)}
          type={componentProps.type}
          placeholder={componentProps.placeholder}
          ref={componentProps.parent_ref}
          autoComplete="off"
          {...componentProps}
        />
      );
  }
};

type InputType = FC<{
  label?: string;
  id: string;
  value: string | boolean;
  name: string;
  className?: string;
  placeholder?: string;
  required?: string;
  readonly?: boolean;
  type?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  hidden?: boolean;
  disabled?: string;
  checked?: boolean
  children?: React.ReactNode;
}>;

const Input: InputType = ({ label, ...props }) => {
  return (
    <>
      {
        (label && props.type !== 'hidden') && (
          <label htmlFor={props.id}>{label}</label>
        )
      }
      <Component {...props} />
    </>
  );
};

export default memo(Input);
