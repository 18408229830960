import React, {
  Children,
  cloneElement,
  Fragment,
  ReactElement,
  RefObject,
  useEffect,
  useState
} from 'react';
import { forwardRef } from 'react';
import DropDownListType from 'types/Select2/DropDownListType';

// eslint-disable-next-line react/display-name
const DropDownList: DropDownListType = forwardRef(({
  children,
  updateSearchQuery,
  setExpanded,onKeyDown,
  search,onChange,
  onScrollBottom,
  ...props
}, ref) => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    updateSearchQuery && updateSearchQuery(searchQuery);
  }, [searchQuery]);

  const currentChildren = (
    search && !updateSearchQuery && searchQuery.length > 0 ? (
      Children.toArray(children).filter((child: ReactElement) => (
        String(child.props.children).toLowerCase().indexOf(searchQuery.toLowerCase()) >
        -1
      ))
    ) : (
      children
    )
  );

  const handleScroll = (e:  React.UIEvent<HTMLUListElement>) => {
    const scrolledToBottom = (
      e.currentTarget.scrollTop === 
      (e.currentTarget.scrollHeight - e.currentTarget.offsetHeight)
    );

    if (onScrollBottom &&  scrolledToBottom) onScrollBottom();
  };

  const handleKeyDown = (e) => {
    onKeyDown(e);
    if(e.key == 'Escape'){
      setExpanded(false);
    }
  }

  return (
    <Fragment>
      {search && (
        <input
          className='select2-search__field search-field'
          autoComplete='off'
          autoCorrect='off'
          autoFocus
          onKeyDown={handleKeyDown}
          onFocus={() => setExpanded(true)}
          onChange={e => { setSearchQuery(e.target.value); } }
          value={searchQuery}
        />
      )}
      <ul
        className='select2-results__options search-field'
        ref={ref as RefObject<HTMLUListElement>}
        onScroll={handleScroll}
        {...props}
      >
        {
          Children.map(
            currentChildren,
            child => child && cloneElement(child as ReactElement, { setExpanded ,onChange})
          )
        }
      </ul>
    </Fragment>
  );
});

export default DropDownList;
