import EnrollmentResource from 'types/enrollments/EnrollmentResource';

const resourceHasChanged = (
  prev: EnrollmentResource,
  curr: EnrollmentResource
): boolean => {
  if (!prev && curr) return true;

  return (
    Boolean(Object.entries(curr).find(([resourceKey, value]) => {
      return value !== prev[resourceKey];
    }))
  );
};

export default resourceHasChanged;
