import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import Prescription from 'types/faxes_ins_cases/Prescription';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Card from 'components/Bootstrap/Card';
import Row from 'components/Bootstrap/Row';
import Col from 'components/Bootstrap/Col';
import CaseFormInput from 'components/faxes_ins_cases/New/CaseFormInput';
import DiagnosisCode from 'types/DiagnosisCode';
import InjectionSites from 'types/InjectionSites';
import DrugAttributeForm from 'components/faxes_ins_cases/New/DrugAttributeForm';
import Select2 from 'components/Select2/Select2';
import DynamicAttributesForm from '../DynamicAttributesForm';

type PrescriptionAttributeFormProps = ResourceForm & {
  prescription: Prescription;
  attr_hash?: any;
};

const PrescriptionAttributeForm: FC<PrescriptionAttributeFormProps> = ({
  prescription = {} as Prescription,
  onChange,
  visible,
  attr_hash,
}) => {
  const [diagnosisCodes, setDiagnosisCodes] = useState([]);

  const [selectedDiagnosisCodes, setSelectedDiagnosisCodes] = useState([]);
  const [query, setQuery] = useState('')

  const [injectionSite, setInjectionSite] =
    useState<keyof typeof InjectionSites>();
  const typeProps = visible ? {} : { type: 'hidden' };
  const inputProps = {
    resource: prescription,
    onChange: onChange,
    nestedResourceName: 'prescription',
    ...typeProps,
  };


  const diagnoseCodeChanged = (diagnosesCodes) => {
    setSelectedDiagnosisCodes(
      diagnosesCodes.map((diagnosisCode) => {
        return diagnosisCode.code;
      })
    );
  };

  useEffect(() => {
    const event = {
      target: { value: selectedDiagnosisCodes },
    };

    onChange(
      event as unknown as ChangeEvent<HTMLInputElement>,
      'diagnosis_code'
    );
  }, [selectedDiagnosisCodes]);

  const diagnosisCodeFromID = (diagnosisID: string) => {
    const diagnosisObj = diagnosisCodes.find((obj) => obj.code === diagnosisID);
    return diagnosisCodeName(diagnosisObj);
  };

  const diagnosisCodeName = (diagnosisCode: DiagnosisCode) => {
    if (!diagnosisCode) return;

    return diagnosisCode.code + ' - ' + diagnosisCode.name;
  };

  useEffect(() => {
    const fetchDiagnosisCodes = async () => {
      const resp = await fetch(`/ref_icds.json?search=${query}`);

      if (resp.ok) setDiagnosisCodes(await resp.json());
    };

    fetchDiagnosisCodes();
  }, [query]);

  const handleKeyDown = (e) =>{
    setQuery(e.target.value)
  }

  return (
    <Card mb={2} mx={2} mt={2}>
      {/*<Card.Header clearfix>Prescription Attributes</Card.Header>*/}
      <Card.Body p={2}>
        <Row>
          <Col sm={12}>
            <CaseFormInput
              label="Diagnosis Codes"
              attribute="diagnosis_code"
              type="hidden"
              {...inputProps}
            />
            <label>Diagnosis Codes</label>
            <Select2
              value={
                selectedDiagnosisCodes
                  ? selectedDiagnosisCodes.map((element) =>
                      diagnosisCodeFromID(element)
                    )
                  : []
              }
              onChange={diagnoseCodeChanged}
              displayName={diagnosisCodeName}
              onKeyDown={handleKeyDown}
              placeholder="Select a Diagnosis Code..."
              multiple={true}
              search={true}
            >
              {diagnosisCodes.map((diagnosisCode: DiagnosisCode) => {
                return (
                  <Select2.Option
                    key={diagnosisCode.code}
                    resource={diagnosisCode}
                  >
                    {diagnosisCodeName(diagnosisCode)}
                  </Select2.Option>
                );
              })}
            </Select2>
          </Col>
        </Row>
        <DrugAttributeForm
          onChange={onChange}
          prescription={prescription}
          visible={visible}
        />
        <DynamicAttributesForm
          attributes_of={'prescription'}
          attr_hash={attr_hash}
          onChange={onChange}
        />
      </Card.Body>
    </Card>
  );
};

export default PrescriptionAttributeForm;
