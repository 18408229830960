import React, { FC, memo } from 'react';
import EnrollmentPractice from 'types/enrollments/EnrollmentPractice';
import EnrollmentResource from 'types/enrollments/EnrollmentResource';
import resourceHasChanged from 'utils/enrollments/resourceHasChanged';
import usePossibleExistingPractices from '../../hooks/enrollments/usePossibleExistingPractices';
import Paths from '../../types/Paths';
import ResourcesSummary from './ResourcesSummary';

type Props = {
  practice: EnrollmentPractice;
  usePracticeValues: (possiblePractice: EnrollmentPractice) => void;
};

const PossibleExistingPracticesBody = ({ possiblePractices, usePracticeValues }) => {
  return (
    <ResourcesSummary>
      <ResourcesSummary.Title>Possible Existing Practices</ResourcesSummary.Title>
      <ResourcesSummary.Header headers={['ID', 'Phone', 'NPI', 'Address']}>
        <th></th>
      </ResourcesSummary.Header>
      <ResourcesSummary.Body
        resourcesPath={Paths.PRACTICE}
        resources={possiblePractices as EnrollmentResource[]}
        attributes={['id', 'phone_number', 'npi', 'address']}
      >
        {(possiblePractice: EnrollmentPractice, index: number) => (
          <td key={index}>
            <a
              className="btn btn-sm btn-info"
              onClick={() => { usePracticeValues(possiblePractice); }}
              style={{ color: 'white' }}
            >
              {'>>'}
            </a>
          </td>
        )}
      </ResourcesSummary.Body>
    </ResourcesSummary>
  );
};

const bodyPropsAreEqual = (prevProps, props) => {
  return (
    props.possiblePractices.every((practice: EnrollmentResource, index: number) => {
      return (
        !resourceHasChanged(
          prevProps.possiblePractices[index] as EnrollmentResource,
          practice as EnrollmentResource)
      );
    })
  );
};

const MemoizedPossibleExistingPracticesBody = memo(
  PossibleExistingPracticesBody,
  bodyPropsAreEqual
);

const PossibleExistingPractices: FC<Props> = ({ practice, usePracticeValues }) => {
  const possiblePractices = usePossibleExistingPractices(practice) as EnrollmentPractice[];

  return (
    <MemoizedPossibleExistingPracticesBody
      possiblePractices={possiblePractices}
      usePracticeValues={usePracticeValues}
    />
  );
};

const propsAreEqual = (prevProps: Props, props: Props) => {
  return (
    !resourceHasChanged(
      prevProps.practice as EnrollmentResource,
      props.practice as EnrollmentResource)
  );
};

export default memo(PossibleExistingPractices, propsAreEqual);
