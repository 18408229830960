import RefTaxonomy from '../../../types/enrollments/RefTaxonomy';

const refTaxonomyDisplayName = (refTaxonomy: RefTaxonomy): string => {
  if (!refTaxonomy) return '';

  const { code, category, classification } = refTaxonomy;

  return (
    code || category || classification ?
      `${code} - ${category} - ${classification}` :
      ''
  );
};

export default refTaxonomyDisplayName;
