import { DEFAULT_PROPERTIES } from './boostrapClassName';

const propsWithoutBootstrap =
  (props: { [key: string]: unknown; }): { [key: string]: unknown; } => {
    const newProps = DEFAULT_PROPERTIES.reduce((acc, property) => {
      delete acc[property];

      return acc;
    }, { ...props });

    return newProps;
  };

export default propsWithoutBootstrap;
