import React, {useEffect} from 'react';
import OptionType from '../../types/Select2/OptionType';

const Option: OptionType = ({ children, onClick, setExpanded,highlighted,index,onMouseEnter,key,resource,onChange,selected, ...props }) => {
  useEffect(() => {
    if(highlighted){
      let elem = document.getElementsByClassName("highlighted select-option")[0]
          elem && elem.scrollIntoView({block: false ? "end" : "nearest", inline: "nearest"});
    }
  },[highlighted])
  const handleOnClick = (e) => {
    onClick && onClick(e,resource);
    onChange && onChange(resource);
    setExpanded(false);
  };

  return (
    <li onMouseEnter={onMouseEnter} {...props} onClick={handleOnClick} className={ highlighted ? "highlighted select-option" : (resource && selected && selected.indexOf(resource) >= 0 ) ? "select-option selected" : "select-option"}>
      {children}
    </li>
  );
};
export default Option;
