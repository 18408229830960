import Col from 'components/Bootstrap/Col';
import Row from 'components/Bootstrap/Row';
import { GetNpiSummary } from 'hooks/enrollments/useNpiSummaries';
import React, {Dispatch, FC, memo, useState} from 'react';
import EnrollmentContact from 'types/enrollments/EnrollmentContact';
import EnrollmentError from 'types/enrollments/EnrollmentError';
import EnrollmentResourceNames from 'types/enrollments/EnrollmentResourceNames';
import FieldsReducerAction from 'types/enrollments/FieldsReducerAction';
import EnrollmentCard from './EnrollmentCard';
import EnrollmentFormInput from './EnrollmentFormInput';
import MemoizedFormInputs from './MemoizedFormInputs';
import MemoizedResourceCard from './MemoizedResourceCard';
import PossibleExistingUsers from './PossibleExistingUsers';
import Input from "components/Input";

const Body = (
  { index, resource, resourceDispatch, errors }:
  {
    index: number;
    resource: EnrollmentContact;
    resourceDispatch: Dispatch<FieldsReducerAction>;
    getNpiSummary: GetNpiSummary;
    errors: EnrollmentError[];
  }
) => {
  const inputProps = {
    index,
    resourceName: RESOURCE_NAME,
    resource,
    resourceDispatch,
    errors
  };
  const [hiddenField, setHiddenField] =useState<boolean>(false);
  const acceptCheckboxChanged = (event) => {
      // if (event.target.checked === false) {
      //     let text = 'Are you sure you want to override the user?'
      //     if (confirm(text) === true ) setHiddenField(true)
      //     else setHiddenField(false);
      // }
  }

  return (
    <Row key={index}>
      <Col sm={2}>
        <EnrollmentFormInput
          label='First Name'
          attribute='firstname'
          {...inputProps}
        />
      </Col>
      <Col sm={2}>
        <EnrollmentFormInput
          label='Middle Name'
          attribute='middlename'
          {...inputProps}
        />
      </Col>
      <Col sm={2}>
        <EnrollmentFormInput
          label='Last Name'
          attribute='lastname'
          {...inputProps}
        />
      </Col>
      <Col sm={4}>
        <EnrollmentFormInput
          label='Email'
          attribute='email'
          {...inputProps}
        />
      </Col>
      <Col sm={1}>
        <EnrollmentFormInput
          type='checkbox'
          label='Active'
          attribute='active'
          {...inputProps}
        />
      </Col>
      <Col sm={1}>
        <EnrollmentFormInput
          type='checkbox'
          label='Accept'
          attribute='accept'
          eventChange={acceptCheckboxChanged}
          {...inputProps}
        />
      </Col>
        {hiddenField && (
            <Col>
            <input
                type='hidden'
                id='override_user'
                name='override_user'
                value='true'
            />
            </Col>
        )}
    </Row>
  );
};

const RESOURCE_NAME = EnrollmentResourceNames.enrollment_contacts;

const AdditionalSummaries:
  // eslint-disable-next-line react/display-name
  FC<{ resource: EnrollmentContact }> = memo(({ resource }) => { 
    return <PossibleExistingUsers user={resource} />;
  }
);

const ContactsCard: FC<{
  contacts: EnrollmentContact[];
  resourceDispatch: Dispatch<FieldsReducerAction>;
  errors: EnrollmentError[]; 
}> = ({ contacts, resourceDispatch, errors }) => {
  return (
    <EnrollmentCard
      title='Users'
      AdditionalSummaries={AdditionalSummaries}
      resources={contacts}
      resourceName={RESOURCE_NAME}
      resourceDispatch={resourceDispatch}
      errors={errors}
      addResourceButton
    >
      {Body}
    </EnrollmentCard>
  );
};

export default MemoizedResourceCard(ContactsCard);
