enum CaseCategories {
  'med' = 'Med Ben',
  'rx' = 'Rx Ben',
  'medrx' = 'Med & Rx Ben',
  'qs' = 'Quick Start',
  'rebate' = 'Rebate',
  'denial' = 'Denial'
}

export default CaseCategories;
