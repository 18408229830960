import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import Col from 'components/Bootstrap/Col';
import Select2 from 'components/Select2/Select2';
import Input from 'components/Input';
type MultiSelectAttributeFormProps = ResourceForm & {
  item?: any;
  capitalizeFirst?:(newValue: any) => any;
  selectPlaceHolder?: (newValue: any) => any;
  handleDynamicFieldId?:(newValue: any) =>  any;
  onChange:  (newValue: any)=> void;
  handleDynamicFieldName?: (newValue: any) => any;
  handleDisableSelectFields?:(newValue: any) => any;
  handleSelectAttributeFields:(fieldName: string, value: any ) => void;
};

type Value =
{
  name: string;
};

const MultiSelectAttributeForm: FC<MultiSelectAttributeFormProps> = ({
  item,
  handleDynamicFieldId,
  handleDynamicFieldName,
  capitalizeFirst,
  handleDisableSelectFields,
  selectPlaceHolder,
  onChange,
  handleSelectAttributeFields,
})=> {
  const [values, setValues] = useState([]);
  useEffect(() =>{
    setValues(item['field_values'].map((val, index)=>{ return { name: val} }))
  },[item]);

  const fieldName = (value: Value)=>{
    console.log("wwwwww",value)
    if(!value) return;
    // const arrayOfHashes = selectedValues.map((value, index) => {
    //       const hash = {};
    //       hash[index] = value;
    //       return hash;
    //     });

    return value.name
  }

  return(

      <Col sm={6}>
      <Input
        key={item['field_name']}
        label={item['field_name']}
        id={handleDynamicFieldId(item['field_name'])}
        type={'hidden'}
        name={handleDynamicFieldName(item['field_name'])}
        value={item['selected']}
      />

      <label>{capitalizeFirst(item['field_name'])}</label>
      <Select2
        multiple={true}
        search={true}
        onChange={onChange}
        displayName={fieldName}
        disabled={handleDisableSelectFields(item['field_name'])}
        value={item['selected']}
        placeholder={selectPlaceHolder(
          capitalizeFirst(item['field_name'])
        )}
      >
          {values.map((value: Value) => {
          return (
            <Select2.Option
              key={value.name}
              onClick={() => {
                handleSelectAttributeFields(
                  item['field_name'],
                  value.name
                );
              }}
              resource={value}

            >
              {value.name}
            </Select2.Option>
          );
        })}
      </Select2>
    </Col>
  );
}

export default MultiSelectAttributeForm;
