/* eslint-disable react/display-name */
import React, {Children, cloneElement, FC, ReactElement, useRef, useContext, useState} from 'react';
import DropDown from './DropDown';
import DropDownList from './DropDownList';
import Selection from './Selection';
import Option from './Option';
import OptionType from '../../types/Select2/OptionType';
import Select2Props from '../../types/Select2/Select2Props';
type Select2Type =
  & FC<Select2Props>
  & {
    Option: OptionType;
  };

const Select2: Select2Type = ({
  children,
  value: selected,
  search,
  multiple,
  placeholder,
  onScrollBottom,
  updateSearchQuery,
  onKeyDown,
  onChange,
  displayName,
  disabled
}) => {
  const [expanded, setExpanded] = useState(false);
  const [highlighted, setHighlighted] = useState({index: undefined,curr: undefined});
  const [selectedValues, setSelectedValues] = useState([]);

  const CLASS_NAMES = [
    `${disabled ? 'disabled ' : ' '}`,
    'select2',
    'select2-container',
    'select2-container--bootstrap',
    'select2-container--below',
    `select2-container--focus${expanded ? '-expanded' : 'skip'}`
  ];

  const parentRef = useRef();
  const handleKeyDown = (e) =>{

    if(!e) return;
    if(e.key == 'ArrowDown') {
      setHighlightedWithOffset(1);
    }else if(e.key == 'ArrowUp') {
      setHighlightedWithOffset(-1);
    }else if (e.key == 'Enter' || e.key == 'Tab') {
      if(highlighted.curr && highlighted.curr.props && (highlighted.curr.props.resource || highlighted.curr.props.onClick)){
        if(onChange){
          handleOnChange(highlighted.curr.props.resource,highlighted.index);
        }else if(highlighted.curr.props.onClick){
          highlighted.curr.props.onClick(e);
        }
      }
    }
    else if(e.target.value){
      onKeyDown && onKeyDown(e)
    }
  };

  const handleOnChange= (resource,index?) => {
    if(!multiple){
      setSelectedValues([resource]);
      onChange && onChange(resource,index);
    }else {
      const existing_index = selectedValues.indexOf(resource);
      if(existing_index > -1) {
        setSelectedValues(selectedValues => selectedValues.filter(res => res != resource));
        onChange && onChange(selectedValues.filter(res => res != resource));
      }else {
        setSelectedValues(selectedValues => ([...selectedValues,resource]));
        onChange && onChange([...selectedValues,resource],index);
      }

    }
  };

  const setHighlightedWithOffset = (offset) => {
    let current_index = highlighted.index;
    if(current_index == undefined){
      current_index = -1;
    }
    current_index += offset;
    current_index = Math.min(current_index,Children.toArray(children).length-1);
    current_index = Math.max(current_index,0);
    setHighlighted({index: current_index,curr: Children.toArray(children)[current_index]});
  };

  const handleMouseEntered = (curr,index) => {
    setHighlighted({index: index,curr: curr});
  };
  return (
    <span
      className={CLASS_NAMES.join(' ')}
      ref={parentRef}
    >
      <Selection
        parentRef={parentRef}
        selected={multiple ? selectedValues : selected}
        expanded={expanded}
        setExpanded={setExpanded}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onChange={handleOnChange}
        multiple  = {multiple}
        displayName={displayName}
      >
        <DropDown>
          <DropDownList
            search={search}
            onScrollBottom={onScrollBottom}
            updateSearchQuery={updateSearchQuery}
            onChange={handleOnChange}
          >
            {
              Children.map(
                children,
                (child,index) => child && cloneElement(child as ReactElement, { setExpanded, highlighted: highlighted.index == index,index,onChange: handleOnChange,onMouseEnter: (e) => {handleMouseEntered(e,index);},selected: selectedValues})
              )
            }
          </DropDownList>
        </DropDown>
      </Selection>
    </span>
  );
};

Select2.Option = Option;

export default Select2;
