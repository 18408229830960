import React, { forwardRef } from 'react';
import BootstrapType from '../../types/Bootstrap/BootstrapType';
import bootstrapClassName from '../../utils/Bootstrap/boostrapClassName';

const Row =
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement, BootstrapType>(
    ({ children, style, ...props }, ref) => {
      const refProps = ref ? { ref } : {};

      return (
        <div
          className={bootstrapClassName('row', [], props)}
          style={style}
          {...refProps}
        >
          {children}
        </div>
      );
    }
  );

export default Row;
