import pluralize from 'pluralize';
import railsNestedResource from '../railsNestedResources';

const nestedResourceId =
  (resourceName: string, index: number, attribute: string): string => {
    return (
      railsNestedResource(
        'enrollment',
        pluralize(resourceName),
        index,
        attribute
      )
        .id
    );
  };

export default nestedResourceId;
