import React, { memo } from 'react';

const RemoveButton = ({ removeResource }) => (
  <div className="text-right" style={{ color: 'white' }}>
    <a
      className="remove_fields btn btn-danger"
      onClick={removeResource}
    >
    Remove
    </a>
  </div>
);

export default memo(RemoveButton);
