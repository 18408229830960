import React, { FC, useState } from 'react';
import RefTaxonomy from 'types/enrollments/RefTaxonomy';
import refTaxonomyDisplayName from 'utils/enrollments/DoctorsCard/refTaxonomyDisplayName';
import RefTaxonomyDropDown from './RefTaxonomyDropDown';

const Demo: FC = () => {
  const [value, setValue] = useState(null);

  const handleOnChange = (refTaxonomy: RefTaxonomy) => {
    setValue(refTaxonomyDisplayName(refTaxonomy));
  };

  return (
    <RefTaxonomyDropDown
      value={value}
      onChange={handleOnChange}
    />
  );
};

export default Demo;
