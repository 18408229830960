const enrollmentCardTestId = (
  id: string,
  resourceName: string,
  index?: number
): string => {
  const base = `${id}-${resourceName}`;

  if (index !== undefined && index !== null) {
    return `${base}-${index}`;
  } else {
    return base;
  }
};

export default enrollmentCardTestId;