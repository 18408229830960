import React, { Children, cloneElement, ReactElement } from 'react';
import SelectionType from '../../types/Select2/Selection';
import ModalDropDown from './ModalDropDown';

const Selection: SelectionType = ({
  setExpanded,
  expanded,
  selected,
  parentRef,
  children,
  placeholder,
  onKeyDown,onChange,multiple,displayName
}) => {

  const handleButtonPress = (e,resource) => {
    onChange(resource);
    e.stopPropagation();
  };

  const handleButtonKeyDown = (e,resource) => {
    if(e.key == 'Backspace'){
      handleButtonPress(e,resource);
      e.stopPropagation();
    }
  };

  const handleKeyDown = (e) => {
    if(!expanded && (e.key == 'ArrowDown' || e.key == 'Enter')){
      setExpanded(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if(!expanded && e.key == 'Tab'){
      return;
    }

    if(expanded && (e.key == 'Tab' || e.key == 'Enter')){
      setExpanded(false);
    }
    if (e.key == 'ArrowDown' || e.key == 'ArrowUp') {
      e.preventDefault();
      e.stopPropagation();
    }
    // onKeyDown({custom_type: 'initial_expand'});
    // }else
    if(e.key == 'Escape') {
      setExpanded(false);
      return;
    }
    onKeyDown(e);
  };
  return (
    <span tabIndex={0} onClick={() => !expanded && setExpanded(true)} onKeyDown={(event) => {
      handleKeyDown(event);
    }}>
      {multiple &&

          <div
            className={
              `form-control form-control-sm select2-selection select2-selection--multiple${expanded ? ' expanded' : ''}`
            }
          >
            <ul className={'select2-selection__rendered'}>
              {
                selected.map ((resource,index) => {
                  return (
                    <li className={'select2-selection__choice'} key={'option-'+index} tabIndex={0} onKeyDown={(e) => handleButtonKeyDown(e,resource)}>
                      <button type="button" className="select2-selection__choice__remove border-right border-left-0 border-top-0 border-bottom-0 border-dark pr-1 pl-1" tabIndex={-1}
                        title="Remove item" aria-label="Remove item"><span
                          aria-hidden="true" onClick={(e) => handleButtonPress(e,resource)}>×</span></button>
                      <span className={'select2-selection__choice__display'}>{displayName ? displayName(resource) :  resource}</span></li>
                  );
                })
              }
            </ul>
          </div>

      }
      {
        !multiple &&
        <div
          className={
            `form-control form-control-sm select2-selection select2-selection--single${expanded ? ' expanded' : ''}`
          }
        >
          <span className='select2-selection__rendered'>
            {selected ? selected : placeholder}
          </span>
          <span className='select2-selection__arrow'>
            <b className={`dropdown-arrow${expanded ? ' expanded' : ''}`}/>
          </span>
        </div>
      }
      {
        expanded &&
        Children.map(
          children,
          child => cloneElement(child as ReactElement, {setExpanded, onKeyDown,onChange}))
      }
      {expanded && <ModalDropDown setExpanded={setExpanded} parentRef={parentRef} />}
    </span>
  );
};
export default Selection;
