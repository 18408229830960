import React, {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import Card from './Bootstrap/Card';

pdfjs.GlobalWorkerOptions.workerSrc =
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DirectionButton = ({children, onClick}) => {
  return (
    <a
      className="btn btn-sm btn-info"
      onClick={onClick}
      style={{color: 'white', margin: '5px'}}
    >
      {children}
    </a>
  );
};

type ZoomControlProps = {
  zoom: number,
  handleZoomIn: () => void;
  handleZoomOut: () => void;
};

const ZoomControl: FC<ZoomControlProps> =
  ({zoom, handleZoomIn, handleZoomOut}) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span>Zoom</span>
        <DirectionButton onClick={handleZoomOut}>
          -
        </DirectionButton>
        <span>{Math.round(Number(zoom * 100))}%</span>
        <DirectionButton onClick={handleZoomIn}>
          +
        </DirectionButton>
      </div>
    );
  };

const PdfViewer: FC<{ url: string, height: number, onChange: (attribute: string) => void; }> = ({url, height, onChange}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [counter, setCounter] = useState(0);

  const updateCount = () => {
    if(counter == 5){
      setCounter(2)
    }else{
      setCounter(counter+1)
    }
  };

  const RotateControl = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <a
          className="btn btn-sm btn-info"
          onClick={updateCount}
          style={{color: 'white', margin: '5px'}}
        >
          Rotate
        </a>
      </div>
    );
  };

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
  };

  const withinBounds = (value: number, min: number, max: number) => {
    return Math.min(Math.max(value, min), max);
  };

  const handleDirectionPress = (direction: number) => {
    setPageNumber(withinBounds(pageNumber + direction, 1, numPages));
  };

  const handleZoomIn = () => {
    setScale(withinBounds(scale + 0.1, 0.1, 5));
  };

  const handleZoomOut = () => {
    setScale(withinBounds(scale - 0.1, 0.1, 5));
  };

  const headerRef = useRef<HTMLDivElement>();
  const bodyRef = useRef<HTMLDivElement>();
  const bodyHeight = height - (headerRef.current?.offsetHeight || 0);

  useEffect(() => {
    onChange(numPages);
  }, [numPages]);

  return (
    <div style={{position: 'sticky', top: 0,}}>
      <Card>
        <Card.Header ref={headerRef}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <DirectionButton onClick={() => {
                handleDirectionPress(-1);
              }}>
                {'<<'}
              </DirectionButton>
              <p style={{margin: 0}}>
                Page {pageNumber} of {numPages}
              </p>
              <DirectionButton onClick={() => {
                handleDirectionPress(1);
              }}>
                {'>>'}
              </DirectionButton>
            </div>
            <ZoomControl
              zoom={scale}
              handleZoomIn={handleZoomIn}
              handleZoomOut={handleZoomOut}
            />
            <RotateControl/>
          </div>
        </Card.Header>
        <Card.Body
          style={{
            height: bodyHeight,
            overflow: 'auto',
            padding: 0
          }}
          ref={bodyRef}
        >
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              pageNumber={pageNumber}
              height={bodyHeight}
              width={(bodyRef.current?.offsetWidth || 0) * scale}
              rotate={90*counter}
            />
          </Document>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PdfViewer;
