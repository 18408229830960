import React, { FC, ReactChild } from 'react';
import BootstrapType from 'types/Bootstrap/BootstrapType';
import bootstrapClassName from 'utils/Bootstrap/boostrapClassName';
import propsWithoutBootstrap from 'utils/Bootstrap/propsWithoutBootstrap';

type Props = Omit<BootstrapType, 'children'> | {
  children: (props: BootstrapType) => ReactChild;
}

const ErrorComponent: FC<Props> = ({ children, ...props }) => (
  <div
    className={bootstrapClassName('message', [], props)}
    {...propsWithoutBootstrap(props as { [key: string]: unknown })}
  >
    {children}
  </div>
);

export default ErrorComponent;
