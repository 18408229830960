import React, { cloneElement, Children, ReactElement } from 'react';
import DropDownType from 'types/Select2/DropDownType';

const DropDown: DropDownType = ({
  setExpanded,
  onKeyDown,
  onChange, children,
}) => {
  return (
    <span
      className='select2-container select2-container--bootstrap select2-container--open'
    >
      <span className='select2-dropdown select2-dropdown--below'>
        <span className='select2-results results'>
          {
            Children.map(
              children,
              child => cloneElement(child as ReactElement, { setExpanded,onKeyDown,onChange })
            )
          }
        </span>
      </span>
    </span>
  );

};

export default DropDown;