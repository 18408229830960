import React, { FC, forwardRef, ForwardRefExoticComponent } from 'react';
import BootstrapType from 'types/Bootstrap/BootstrapType';
import bootstrapClassName from 'utils/Bootstrap/boostrapClassName';

type CardType = 
  & FC<BootstrapType>
  & {
    Header: ForwardRefExoticComponent<
      & BootstrapType
      & React.RefAttributes<HTMLDivElement>
     >;
    Body: ForwardRefExoticComponent<
      & BootstrapType
      & React.RefAttributes<HTMLDivElement>
     >;
  }

const Card: CardType = ({ children, ...props }) => (
  <div className={bootstrapClassName('card', ['nestd-fields'], props)}>
    {children}
  </div>
);

const Header = 
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement, BootstrapType>(
    ({ children, ...props }, ref) => {
      const refProps = ref ? { ref } : {};

      return (
        <div
          className={bootstrapClassName('card-header', ['clearfix'], props)}
          {...refProps}
        >
          {children}
        </div>
      );
    }
  );

const Body = 
  forwardRef<HTMLDivElement, BootstrapType>(
    ({ children, style, ...props }, ref) =>  {
      const refProps = ref ? { ref } : {};

      return (
        <div
          className={bootstrapClassName('card-body', ['overflow-auto'], props)}
          style={style}
          {...refProps}
        >
          {children}
        </div>
      );
    }
  );

Card.Header = Header;
Card.Body = Body;

export default Card;
