import React, { ChangeEvent, Dispatch, FC, memo, useMemo } from 'react';
import railsNestedResource from '../../utils/railsNestedResources';
import EnrollmentCard from './EnrollmentCard';
import refTaxonomyDisplayName from '../../utils/enrollments/DoctorsCard/refTaxonomyDisplayName';
import enrollmentCardTestId from '../../utils/enrollments/enrollmentCardTestId';
import EnrollmentResourceNames from 'types/enrollments/EnrollmentResourceNames';
import Row from 'components/Bootstrap/Row';
import Col from 'components/Bootstrap/Col';
import EnrollmentFormInput from './EnrollmentFormInput';
import Select2 from 'components/Select2/Select2';
import Card from 'components/Bootstrap/Card';
import EnrollmentDoctor from 'types/enrollments/EnrollmentDoctor';
import { NpiSummaryCache, GetNpiSummary } from 'hooks/enrollments/useNpiSummaries';
import { DefaultEnrollmentDoctor } from 'types/enrollments/Doctor';
import FieldsReducerAction from 'types/enrollments/FieldsReducerAction';
import RefTaxonomy from 'types/enrollments/RefTaxonomy';
import EnrollmentError from 'types/enrollments/EnrollmentError';
import MemoizedResourceCard from './MemoizedResourceCard';

const RESOURCE_NAME = EnrollmentResourceNames.enrollment_doctors;

const enrollmentDoctorUserName =
  (index: number, attribute: keyof EnrollmentDoctor | 'email') => (
    railsNestedResource(
      'enrollment_doctors',
      'enrollment_doctor_user_attributes',
      index,
      attribute
    )
      .name
  );

const enrollmentDoctorUserId =
  (index: number, attribute: keyof EnrollmentDoctor | 'email') => (
    railsNestedResource(
      'enrollment_doctors',
      'enrollment_doctor_user_attributes',
      index,
      attribute
    )
      .id
  );

const Body = (
  { index, resource, resourceDispatch, getNpiSummary, refTaxonomies, errors }:
  {
    index: number;
    resource: EnrollmentDoctor;
    resourceDispatch: Dispatch<FieldsReducerAction>;
    getNpiSummary: GetNpiSummary;
    refTaxonomies: RefTaxonomy[];
    errors: EnrollmentError[];
  }
) => {
  const inputProps = {
    index,
    resourceName: RESOURCE_NAME,
    resource,
    resourceDispatch,
    errors
  };

  const refTaxonomyValue = useMemo(() => {
    return (
      refTaxonomyDisplayName(
        refTaxonomies.find(({ id }) => Number(resource.ref_taxonomy_id) === id)
      )
    );
  }, [resource.ref_taxonomy_id]);

  const handleSelectOnClick = (value: number) => {
    resourceDispatch({
      resourceName: RESOURCE_NAME,
      type: 'change',
      index,
      event: {
        target: {
          value: String(value)
        }
      } as ChangeEvent<HTMLInputElement>,
      attribute: 'ref_taxonomy_id'
    });
  };

  return (
    <>
      <Row>
        <Col sm={5}>
          <EnrollmentFormInput
            label='NPI'
            attribute='npi'
            {...inputProps}
          />
        </Col>
        <Col sm={5}>
          <EnrollmentFormInput
            label='Specialty'
            attribute='ref_taxonomy_id'
            type='select'
            hidden
            {...inputProps}
          > 
            <Select2
              value={refTaxonomyValue}
              search
            >
              {refTaxonomies.map(refTaxonomy => {
                return(
                  <Select2.Option
                    key={refTaxonomy.id}
                    onClick={() => handleSelectOnClick(refTaxonomy.id)}
                  >
                    {refTaxonomyDisplayName(refTaxonomy)}
                  </Select2.Option>
                );
              })}
            </Select2>
          </EnrollmentFormInput>
        </Col>
        <Col sm={1}>
          <EnrollmentFormInput
            label='Active'
            attribute='active'
            type='checkbox'
            {...inputProps}
          />
        </Col>
        <Col sm={1}>
          <EnrollmentFormInput
            label='Accept'
            attribute='accept'
            type='checkbox'
            {...inputProps}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <EnrollmentFormInput
            label='First Name'
            attribute='firstname'
            {...inputProps}
          />
        </Col>
        <Col sm={3}>
          <EnrollmentFormInput
            label='Middle Name'
            attribute='middlename'
            {...inputProps}
          />
        </Col>
        <Col sm={3}>
          <EnrollmentFormInput
            label='Last Name'
            attribute='lastname'
            {...inputProps}
          />
        </Col>
        <Col sm={3}>
          <EnrollmentFormInput
            label='Tax ID'
            attribute='tax_id'
            {...inputProps}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <EnrollmentFormInput
            label='DEA'
            attribute='dea'
            {...inputProps}
          />
        </Col>
        <Col sm={3}>
          <EnrollmentFormInput
            label='PTAN'
            attribute='ptan'
            {...inputProps}
          />
        </Col>
        <Col sm={3}>
          <EnrollmentFormInput
            label='Phone Number'
            attribute='phone_number'
            {...inputProps}
          />
        </Col>
        <Col sm={3}>
          <EnrollmentFormInput
            label='Fax Number'
            attribute='fax_number'
            {...inputProps}
          />
        </Col>
      </Row>
      <Card
        mt={2}
        mb={2}
        data-testid={
          enrollmentCardTestId(
            'doctor-user-card',
            'enrollment_doctors',
            index
          )
        }
      >
        <Card.Header>
          Doctor User:
        </Card.Header>
        <Card.Body>
          <Row>
            <Col sm={3}>
              <EnrollmentFormInput
                attribute='enrollment_doctor_user_firstname'
                label='First Name'
                name={enrollmentDoctorUserName(index, 'firstname')}
                id={enrollmentDoctorUserId(index, 'firstname')}
                {...inputProps}
              />
            </Col>
            <Col sm={2}>
              <EnrollmentFormInput
                attribute='enrollment_doctor_user_middlename'
                label='Middle Name'
                name={enrollmentDoctorUserName(index, 'middlename')}
                id={enrollmentDoctorUserId(index, 'middlename')}
                {...inputProps}
              />
            </Col>
            <Col sm={3}>
              <EnrollmentFormInput
                attribute='enrollment_doctor_user_lastname'
                label='Last Name'
                name={enrollmentDoctorUserName(index, 'lastname')}
                id={enrollmentDoctorUserId(index, 'lastname')}
                {...inputProps}
              />
            </Col>
            <Col sm={3}>
              <EnrollmentFormInput
                attribute='enrollment_doctor_user_email'
                label='Email'
                name={enrollmentDoctorUserName(index, 'email')}
                id={enrollmentDoctorUserId(index, 'email')}
                {...inputProps}
              />
            </Col>
            <Col sm={1}>
              <EnrollmentFormInput
                attribute='enrollment_doctor_user_accept'
                label='Accept'
                type='checkbox'
                name={enrollmentDoctorUserName(index, 'accept')}
                id={enrollmentDoctorUserId(index, 'accept')}
                {...inputProps}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

const DoctorsCard: FC<{
  doctors: (EnrollmentDoctor | DefaultEnrollmentDoctor)[];
  npiSummaries: NpiSummaryCache;
  getNpiSummary: GetNpiSummary;
  resourceDispatch: Dispatch<FieldsReducerAction>;
  refTaxonomies: RefTaxonomy[];
  errors: EnrollmentError[]; 
}> = ({
  doctors,
  npiSummaries,
  getNpiSummary,
  resourceDispatch,
  refTaxonomies,
  errors
}) => {
  return (
    <EnrollmentCard
      title='Doctors'
      npiSummary
      resources={doctors}
      resourceName={RESOURCE_NAME}
      resourceDispatch={resourceDispatch}
      npiSummaries={npiSummaries}
      getNpiSummary={getNpiSummary}
      refTaxonomies={refTaxonomies}
      errors={errors}
      addResourceButton
    >
      {Body}
    </EnrollmentCard>
  );
};

export default MemoizedResourceCard(DoctorsCard);
