import { memo } from 'react';

const MemoizedFormInputs = (inputs) => memo(
  inputs,
  (prevProps, props) => {
    if (prevProps) {
      return prevProps.index === props.index;
    } else {
      return false;
    }
  });

export default MemoizedFormInputs;
