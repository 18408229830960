import BootstrapType from '../../types/Bootstrap/BootstrapType';

export const DEFAULT_PROPERTIES = [
  'm',
  'mt',
  'mb',
  'mx',
  'my',
  'p',
  'px',
  'pb',
  'clearfix',
  'form-row'
];

const bootstrapClassName = (
  className: string,
  properties: string[],
  props: BootstrapType
): string => (
  Array.from(new Set(properties.concat(DEFAULT_PROPERTIES))).reduce((acc, property) => {
    let space = '';
    if (acc.length > 0) space = ' ';

    let value = `${space}`;

    if (props[property] !== 0 && !props[property]) return acc;

    if (Boolean(props[property]) === props[property]) {
      value += property;
    } else {
      value += `${property}-${props[property]}`;
    }

    return `${acc}${value}`;
  }, className || '')
);

export default bootstrapClassName;
