import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import Prescription from 'types/faxes_ins_cases/Prescription';
import React, {FC, useEffect, useState} from 'react';
import Col from 'components/Bootstrap/Col';
import CaseFormInput from 'components/faxes_ins_cases/New/CaseFormInput';
import Row from 'components/Bootstrap/Row';
import useDebouncedFetch from 'hooks/useDebouncedFetch';
import flatPicker from 'hooks/faxes_ins/flatPicker';
import DrugAttribute from 'types/DrugAttribute';
import Select2 from 'components/Select2/Select2';

type DrugAttributeFormProps =
  & ResourceForm
  & {
  prescription: Prescription;
};

const DrugAttributeForm: FC<DrugAttributeFormProps> =
  ({ prescription = {} as Prescription, onChange, visible }) => {

    const [drugAttributes, setDrugAttributes] = useState<DrugAttribute[]>([]);
    const [drugAttributeSelection, setDrugAttributeSelection] = useState<any>();
    const typeProps = visible ? {} : { type: 'hidden' };
    const debouncedFetch = useDebouncedFetch();
    const inputProps = {
      resource: prescription,
      onChange: onChange,
      nestedResourceName: 'prescription',
      ...typeProps
    };

    const fetchResource = async (url: string): Promise<[]> => {
      const resp = await fetch(url);
      const body = resp.ok ? await resp.json() : [];
      const resource = await body ? body : [];
      return resource;
    };

    const getDrugAttributes = () => {
      debouncedFetch(() => {
        fetchResource(
          `/drugs/${prescription.drug_id}/drug_attributes.json`)
          .then(
            (curr: DrugAttribute[]) => {
              if(curr) {
                setDrugAttributes(curr);
              } else {
                setDrugAttributes([]);
              }
            });
      });
    };



    const selectedValue = (drug_attribute: DrugAttribute) => {
      if (drugAttributeSelection){
        return drugAttributeSelection[drug_attribute?.id];
      } else {
        return '';
      }
    };

    const handleSelectClick = (drug_attribute: DrugAttribute, selection: string) => {
      setDrugAttributeSelection({
        ...drugAttributeSelection,
        [drug_attribute.id]: selection
      });
    };

    useEffect(() => {
      if (prescription?.drug_id){
        getDrugAttributes();
      }
    }, [prescription?.drug_id]);

    return (
      <Row>

        {drugAttributes?.map((curr: DrugAttribute, index) => {
          if (curr.input_type == 'select'){
            return(
              <Col key={index} sm={6}>
                <CaseFormInput
                  label={curr?.name}
                  attribute={curr?.name}
                  value={selectedValue(curr)}
                  type='hidden'
                  {...inputProps}
                />
                <label>{curr?.name}</label>
                <Select2
                  value={selectedValue(curr)}
                  placeholder='Select...'
                >
                  {
                    curr?.input_options?.map((prod: string) => {
                      return(
                        <Select2.Option
                          key={prod}
                          onClick={() => { handleSelectClick(curr,prod); }}
                        >
                          {prod}
                        </Select2.Option>
                      );
                    })
                  }
                </Select2>
              </Col>
            );
          } else {
            return (
              <Col key={index} sm={6}>
                <CaseFormInput
                  label={curr?.name}
                  attribute={curr?.name}
                  {...inputProps}
                />
              </Col>
            );
          }
        })
        }
      </Row>
    );
  };

export default DrugAttributeForm;
