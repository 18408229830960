import React from 'react';
interface ModalProps {
    showModal: boolean;
    closeModal: () => void;
    modalHeader: React.ReactNode;
    modalBody: React.ReactNode;
}
const Modal: React.FC<ModalProps> = ({ showModal, closeModal, modalHeader, modalBody }) => {
    const modalStyle: React.CSSProperties = {
        display: showModal ? 'block' : 'none',
    };

    return (
        <div className={`modal fade ${showModal ? 'show' : ''}`} id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" style={modalStyle}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        {modalHeader}
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {modalBody}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;

