import useSelect, { OnScrollBottom, OnUpdateSearchQuery } from 'hooks/useSelect';
import React, { FC, useEffect, useState } from 'react';
import RefTaxonomy from 'types/enrollments/RefTaxonomy';
import refTaxonomyDisplayName from 'utils/enrollments/DoctorsCard/refTaxonomyDisplayName';
import Select2 from '../Select2/Select2';

type RefTaxonomyDropDownProps = {
  value: number;
  onChange: (refTaxonomy: RefTaxonomy) => void;
  disabled?: boolean
};

const RefTaxonomyDropDown: FC<RefTaxonomyDropDownProps> =
  ({ value: passedInValue, onChange, disabled}) => {
    const [
      onScrollBottom,
      onUpdateSearchQuery,
      collection
    ] = useSelect('/ref_taxonomies');

    const [collectionCache, setCollectionCache] =
      useState(new Set([] as RefTaxonomy[]));

    useEffect(() => {
      setCollectionCache(new Set(collection as RefTaxonomy[]));
    }, [collection]);

    const findRefTaxonomyInCache = (): RefTaxonomy => {
      return (
        Array
          .from(collectionCache)
          .find((refTaxonomy: RefTaxonomy) => {
            return refTaxonomy.id === Number(passedInValue);
          })
      );
    };

    const fetchRefTaxonomy = async (): Promise<RefTaxonomy> => {
      const resp = await fetch(`/ref_taxonomies/${passedInValue}.json`);
      const refTaxonomy = resp.ok ? await resp.json() : {};

      return refTaxonomy;
    };

    const [value, setValue] = useState(null);

    useEffect(() => {
      const fetchValue = async () => {
        const refTaxonomy = (
          findRefTaxonomyInCache() ||
          await fetchRefTaxonomy()
        );

        setValue(refTaxonomyDisplayName(refTaxonomy));
      };

      if (passedInValue) fetchValue();
    }, [passedInValue]);
    
    return (
      <Select2
        value={value}
        search
        disabled={disabled}
        placeholder='Select Specialty'
        onScrollBottom={onScrollBottom as OnScrollBottom}
        updateSearchQuery={onUpdateSearchQuery as OnUpdateSearchQuery}
      >
        {(collection as RefTaxonomy[])?.map((curr: RefTaxonomy) => {
          return (
            <Select2.Option
              key={curr.id}
              onClick={() => {
                onChange(curr);
              }}
            >
              {refTaxonomyDisplayName(curr)}
            </Select2.Option>
          );
        })}
        {!collection?.length && (
          <div style={{ padding: '0.375rem 1.5rem 0.375rem 0.75rem' }}>
            No Results...
          </div>
        )}
      </Select2>
    );
  };

export default RefTaxonomyDropDown;
