import ResourceForm from 'types/faxes_ins_cases/New/ResourceForm';
import Patient from 'types/Patient';
import Practice from 'types/Practice';
import React, {FC, useEffect, useState,useRef} from 'react';
import Col from 'components/Bootstrap/Col';
import CaseFormInput from 'components/faxes_ins_cases/New/CaseFormInput';
import Row from 'components/Bootstrap/Row';
import railsNestedResource from 'utils/railsNestedResources';
import ResourceAddress from 'types/ResourceAddress';
import removeNullFromObject from 'hooks/faxes_ins/removeNullFromObject';


type AddressFormProps =
  & ResourceForm
  & {
  resourceName?: string;
  disabled?: boolean;
}
  & { resource?: Practice | Patient; };

const DEFAULT_ADDRESS = {
  street: '',
  suite: '',
  city: '',
  state: '',
  zip: '',
  lat: 0.0,
  lng: 0.0
};

const AddressForm: FC<AddressFormProps> =
  ({resource,onChange,visible,resourceName,disabled}) => {
    const typeProps = visible ? {} : { type: 'hidden' };

    const [address,setAddress] = useState<ResourceAddress>();

    const inputProps = {
      resource: resource,
      nestedResourceName: 'address',
      onChange,
      disabled: disabled,
      ...typeProps,
    };

    const handleAddressChange = (e,attribute) => {
      setAddress({
        ...address,
        [attribute]: e.target.value
      });
    };

    useEffect(() => {
      if (resource.address){
        removeNullFromObject(resource.address);
        setAddress(resource.address);
      }else{
        setAddress(DEFAULT_ADDRESS);
      }
    },[resource.address]);
    useEffect(() => {
      initializeGoogleMap();
    },[]);
    const initializeGoogleMap = () => {
      const autocomplete = new window.google.maps.places.Autocomplete(parent_ref.current, {types: ['address'], componentRestrictions: { country: 'us' } } );
      autocomplete.addListener('place_changed', function (e) {
        let place = autocomplete.getPlace();

        let google_address = {
          formatted_address: '',
          postal_code: '',
          administrative_area_level_1: '',
          route: '',
          street_number: '',
          locality: '',
          lat: 0.0,
          lng: 0.0
        }
        for (let i = 0; i < place.address_components.length; i++) {
          let addressType = place.address_components[i].types[0];
          let val = place.address_components[i];
          google_address[addressType] = ['administrative_area_level_2', 'administrative_area_level_1'].includes(addressType) ? val.short_name : val.long_name;
        }

        let geo_location = place.geometry.location;
        google_address.lat = geo_location.lat();
        google_address.lng = geo_location.lng();
        google_address.formatted_address = place.formatted_address

        mapPlacesAddress(google_address, place);
      });
    }
    const parent_ref = useRef();

    const mapPlacesAddress = (google_address: any, place: any) => {
      setAddress({
        city: fillCity(google_address),
        state: fillState(google_address),
        suite: google_address.locality,
        zip: google_address.postal_code,
        street: fillStreet(google_address),
        lat: google_address.lat,
        lng: google_address.lng
      });
    }


    const  fillState=(google_address) => {
      if(google_address.administrative_area_level_1 == undefined)
        return google_address.administrative_area_level_2
      else
        return google_address.administrative_area_level_1

    }
    const fillCity = (google_address) => {
      if(google_address.locality == undefined)
        return google_address.political
      else
        return google_address.locality
    }
    const fillStreet = (google_address) => {
      if (google_address.street_number == '' && google_address.route == '') {
        return google_address.formatted_address
      } else {
        return (google_address.street_number + " " + google_address.route)
      }
    }

    const addressInputAttribute =
      (attribute: 'street' | 'suite' | 'city' | 'state' | 'zip' | 'lat' | 'lng') => {
        const { name, id } = railsNestedResource(`${resourceName}_attributes`, 'address', null,attribute);

        return {
          name: name.replace(/(practice|patient)_attributes\[/, `[${resourceName}_attributes][`),
          id
        };
      };

    return (
      <Row>
        <Col sm={6}>
          <CaseFormInput
            {...inputProps}
            label='Street'
            value={address?.street}
            onChange={(e) => {
              handleAddressChange(e, 'street');
            }}
            parent_ref = {parent_ref}
            attribute={addressInputAttribute('street').name}
            id={addressInputAttribute('street').id}
            deeplyNested
          />

          <CaseFormInput
            {...inputProps}
            label='lat'
            type={'hidden'}
            value={String(address?.lat)}
            attribute={addressInputAttribute('lat').name}
            id={addressInputAttribute('lat').id}
            deeplyNested
          />

          <CaseFormInput
            {...inputProps}
            label='lng'
            type={'hidden'}
            value={String(address?.lng)}
            attribute={addressInputAttribute('lng').name}
            id={addressInputAttribute('lng').id}
            deeplyNested
          />
        </Col>
        <Col sm={3}>
          <CaseFormInput
            {...inputProps}
            label='Suite'
            value={address?.suite}
            onChange={(e) => {
              handleAddressChange(e, 'suite');
            }}
            attribute={addressInputAttribute('suite').name}
            id={addressInputAttribute('suite').id}
            deeplyNested
          />
        </Col>
        <Col sm={2}>
          <CaseFormInput
            {...inputProps}
            label='city'
            value={address?.city}
            onChange={(e) => {
              handleAddressChange(e, 'city');
            }}
            attribute={addressInputAttribute('city').name}
            id={addressInputAttribute('city').id}
            deeplyNested
          />
        </Col>
        <Col sm={2}>
          <CaseFormInput
            {...inputProps}
            label='state'
            value={address?.state}
            onChange={(e) => {
              handleAddressChange(e, 'state');
            }}
            attribute={addressInputAttribute('state').name}
            id={addressInputAttribute('state').id}
            deeplyNested
          />
        </Col>
        <Col sm={2}>
          <CaseFormInput

            {...inputProps}
            label='zip'
            value={address?.zip}
            onChange={(e) => {
              handleAddressChange(e, 'zip');
            }}
            attribute={addressInputAttribute('zip').name}
            id={addressInputAttribute('zip').id}
            deeplyNested
          />
        </Col>
      </Row>
    );
  };

export default AddressForm;
