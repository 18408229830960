import React, { FC } from 'react';
import BootstrapType from 'types/Bootstrap/BootstrapType';
import bootstrapClassName from 'utils/Bootstrap/boostrapClassName';

type TableType = {
  Head: FC;
  Body: FC;
};

type TableProps = 
  & BootstrapType 
  & {
  'table-sm'?: boolean;
};

const Head: FC<{'thead-dark'?: boolean; }> = ({
  children,
  'thead-dark': theadDark,
  ...props
}) => (
  <thead
    className={
      bootstrapClassName(
        '',
        ['thead-dark', 'table-sm'],
        { 'thead-dark': theadDark } as BootstrapType
      )
    }
    {...props}
  >
    {children}
  </thead>
);

const Body: FC = ({ children, ...props }) => (
  <tbody {...props}>{children}</tbody>
);

const Table: FC<TableProps> & TableType = ({ children, 'table-sm': tableSm, mb, ...props }) => (
  <div className='table-responsive' {...props}>
    <table
      className={
        bootstrapClassName(
          'table',
          ['table-sm', 'mb'],
          { 'table-sm': tableSm, mb } as BootstrapType
        )
      }
      {...props}
    >
      {children}
    </table>
  </div>
);

Table.Head = Head;
Table.Body = Body;

export default Table;
